import React, { useState, useEffect } from 'react'
import { API } from "aws-amplify";

// import { listResidentials } from "../../../graphql/queries";
import { listResidentialsStatusEve } from "../../../graphql/queries_custom";

import Section from '../../../components/dashboard/section'
import FilterResidential from './filterResidential';
import LoaderWait from '../../../components/loaderWait';

const LIMIT = 100000;

const SearchList = (props) => {
    // const { companiesUsersId, getQueryAPI, title, pathItems, children, typeSearch = 'full', limit = 100000 } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);

    const [valueResidential, setValueResidential] = useState("7d3a84a8-90f7-4511-84e8-5fb5c46fea64");

    const newChildren = React.Children.map(props.children, function (child) {
        return React.cloneElement(child, {
            isLoading,
            idResidential: valueResidential
        })
    });

    const getAPI = async () => {
        try {
            setIsLoading(true);

            const filterCompany = { companiesResidentialId: { eq: props.companiesUsersId } }
            const filterDetail = { filter: { ...filterCompany }, limit: parseFloat(LIMIT) };

            const listQuery = await API.graphql({
                query: listResidentialsStatusEve,
                variables: { ...filterDetail }
            });

            setList(listQuery.data['listResidentials'].items);

        } catch (err) {
            console.log({ err });
        }
        setIsLoading(false);
    }

    const handlerOnSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        getAPI();

    }

    const updateAPI = (value) => {
        setValueResidential(value);
        // getAPI();
    }

    // useEffect(() => {
    //     const changeInservice = API.graphql(
    //         graphqlOperation(onUpdateThings, {})
    //     ).subscribe({
    //         next: ({ provider, value }) => getAPIThings(),
    //         error: error => console.warn(error)
    //         //next: ({ provider, value }) => {
    //         // my code ...
    //         //},
    //         //error: (error) => console.log("update inservice ", error.error.errors[0]),
    //     });

    //     return () => changeInservice.unsubscribe();    // <== add this one
    // }, []);

    useEffect(() => {

        getAPI();

    }, []);

    return (
        <>
            {/* <pre>valueResidential={JSON.stringify(valueResidential)}</pre> */}
            <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
                <Section>
                    <div className="flex flex-col w-full">

                        <form onSubmit={handlerOnSubmit} className="mb-6">
                            <div className="flex flex-col sm:flex-row justify-start">
                                <FilterResidential
                                    companiesUsersId={props.companiesUsersId}
                                    value={valueResidential}
                                    setValue={updateAPI}
                                />
                                <div className="w-32 flex flex-row items-center">
                                    <span className="py-4 sm:pr-2 ">
                                        <input
                                            type="submit"
                                            value="FILTRAR"
                                            className="w-full px-4 py-2 ml-4 bg-energica-red hover:bg-red-500 text-white font-bold rounded-lg text-center"
                                        />
                                    </span>
                                </div>
                            </div>
                            {isLoading ? null : newChildren}
                        </form>
                    </div>

                    <div className="flex flex-col w-full">
                        <div className="overflow-x-scroll lg:overflow-hidden">
                            {/* LISTA DE EVE */}
                        </div>
                    </div>
                </Section>
            </div>

        </>
    )
}

export const MemoizedSearchList = React.memo(SearchList);
export default MemoizedSearchList;