import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Authenticator } from '@aws-amplify/ui-react';
import { Provider } from 'react-redux'
import { store } from './store/store';
import App from './App'
// import * as serviceWorker from './serviceWorker'


ReactDOM.render(
  <Provider store={store}>
    <Authenticator.Provider>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </Authenticator.Provider>
  </Provider>
  ,
  document.getElementById('root')
)


// ReactDOM.render(
//     // <Provider>
//       <App />
//     </Suspense>,
//     // </Provider>,
//   document.getElementById('root')
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
