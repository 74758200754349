import * as types from './constants';
import { fetchProfile } from '../../services/profile.services';
// import { LOADING_START, LOADING_FINISHED } from '../loading/constants';
const MODULE = 'PROFILE';

export const getProfile = (payload) => async (dispatch, getState) => {
    try {

        // const { auth, auth:{ token } } = await getState();

        // console.log('**** getProfile *****', payload)

        // if(token){

        // const dataFetch = await fetchProfile(token);

        // console.log('----dataFetch-profile---', dataFetch);

        // if(dataFetch.response.status === 200){

        dispatch({
            type: types.SET_PROFILE,
            payload: payload
            // payload: {
            //     name: dataFetch.response.name,
            //     pages:[...dataFetch.response.pages],
            // } 
        });

        // }
        // }



    } catch (error) {
        console.log('---error--', error)
    }
}


export const setAuth = payload => ({
    type: types.SET_PROFILE,
    payload
});