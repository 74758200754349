import { I18n } from "aws-amplify";
import { Authenticator } from '@aws-amplify/ui-react';
import { Translations } from "@aws-amplify/ui-components";
import './styles.css';


I18n.putVocabulariesForLanguage("en-US", {
  // Iniciar sessión
  [Translations.SIGN_IN_ACTION]: "Iniciar sesión",
	[Translations.SIGN_UP_PASSWORD_PLACEHOLDER] : 'Clave',
	[Translations.SIGN_IN_TEXT] : 'Ingresar', //  BUTTON
  [Translations.SIGN_IN_ACTION] : 'Ingresar', //TITTLE TAB
	[Translations.FORGOT_PASSWORD_TEXT] : '¿No tienes tu clave?',

  // Crear cuenta
	[Translations.SIGN_UP_SUBMIT_BUTTON_TEXT] : 'Crear cuenta',
  // [Translations.SIGN_UP_PASSWORD_PLACEHOLDER] : 'Clave',
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER] : '--Nombre',
  [Translations.NAME_LABEL] : '--Nombre',
  [Translations.GIVEN_NAME_PLACEHOLDER] : '--Nombre',
  [Translations.FAMILY_NAME_PLACEHOLDER] : '--Nombre',
  [Translations.NICKNAME_PLACEHOLDER] : '--Nombre',
  [Translations.USERNAME_PLACEHOLDER] : '--Nombre',
  [Translations.PREFERRED_USERNAME_PLACEHOLDER] : '--Nombre',
  [Translations.MIDDLE_NAME_PLACEHOLDER] : '--Nombre',
  [Translations.NAME_PLACEHOLDER] : '--Nombre',
  [Translations.NICKNAME_PLACEHOLDER] : '--Nombre',
  
  
  
  
  // Cambiar la clave
  [Translations.RESET_YOUR_PASSWORD] : 'Recuperar clave',
  [Translations.BACK_TO_SIGN_IN] : 'Volver al inicio',
  [Translations.SUBMIT] : 'Cambiar clave',
  // => falta el button

  [Translations.SEND_CODE] : '--Enviar el código',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE] : '--Enviar el código',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE] : '--Enviar el código',
  [Translations.EMAIL_PLACEHOLDER] : '--Ingrese su email',
  [Translations.RESET_PASSWORD_TEXT] : '--Cambiar la clave',
 
 
  // Envio de código de verificación
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Volver a enviar el código",
  
  
  
  
  [Translations.CODE_PLACEHOLDER] : '-- Código',

  [Translations.NEW_PASSWORD_LABEL] : '1- Nueva Clave',
  [Translations.NEW_PASSWORD_PLACEHOLDER] : '2- Nueva Clave',
  [Translations.PASSWORD_LABEL] : '3- Nueva Clave',
  [Translations.PASSWORD_PLACEHOLDER] : '4- Nueva Clave',



  [Translations.CODE_PLACEHOLDER] : '-- Ingrese el código',
	[Translations.CONFIRM_SIGN_UP_CODE_LABEL] : '--Código de confirmación',
	[Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER] : '- Ingrese el código',


  


});


const LoginBase = () => {
  return (
        <Authenticator>
            {({ signOut, user }) => (
            <main>
                <h1>Hello {user.username}</h1>
                <button onClick={signOut}>Sign out</button>
            </main>
            )}
        </Authenticator>
  )
}
export default LoginBase
