/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://xo3s3lpbpjeqdmlpkefefpneeu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-k3aao2lw3re3begvgr5ccn2ysy",
    "aws_cognito_identity_pool_id": "us-east-2:d6cdde16-58eb-402a-81b6-59e533765c70",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_8kqvpmN4d",
    "aws_user_pools_web_client_id": "7q648iellfbphh832b8rkc0up0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "admin.energica.city-master",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d17mss16c39jso.cloudfront.net"
};


export default awsmobile;
