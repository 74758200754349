import React, { useEffect, useState } from "react";

// import { listThingsStatus } from "../../../graphql/queries_custom";
import { listResidentials } from "../../../graphql/queries";
import { API } from "aws-amplify";

const PATH_ITEMS = "listResidentials";
const TIME_OFFLINE = 10;
// const typeOfColor = {
//     // eslint-disable-next-line no-useless-computed-key
//     [""]: "bg-gray-300",
//     // eslint-disable-next-line no-useless-computed-key
//     ["on"]: "bg-green-400",
//     // eslint-disable-next-line no-useless-computed-key
//     ["off"]: "bg-blue-400",
//     // eslint-disable-next-line no-useless-computed-key
//     ["offline"]: "bg-red-400",
// };


const FilterResidential = (props) => {
    const [list, setList] = useState([]);

    // const filterThing = (idThing) => things.filter((i) => i.id === idThing)
    const { value, setValue, companiesUsersId } = props;

    const getAPI = async () => {
        try {

            const listQuery = await API.graphql({
                query: listResidentials,
                variables: {
                    filter: {
                        companiesResidentialId: { eq: companiesUsersId }
                        // companiesResidentialId: { eq: "30265541-d492-45cb-b246-62bef50d16ff" }
                    }
                }
            });

            setList(listQuery.data[PATH_ITEMS].items);

            setValue(listQuery.data[PATH_ITEMS].items[0].id)

        } catch (err) {
            console.log({ err });
        }
    }

    useEffect(() => {

        getAPI();

    }, []);

    return (
        <div className="w-102 flex flex-col sm:flex-row">
            <span className="sm:py-4 sm:pr-2">
                <span className="text-sm font-bold sm:visible text-grey-500">Conjunto residencial</span>
                <select
                    name={'moduleList'}
                    value={value}
                    className='form-select w-full mr-4 mb-4'
                    onChange={(e) => setValue(e.target.value)}
                >
                    <option key={'COMPANY-DEFAULTVALUE'} value={''}>-</option>
                    {
                        list.map((option, j) => (
                            <option key={j} value={option.id}>
                                {option?.name || ''} -[{option?.Company?.name || ''}]
                            </option>
                        ))
                    }
                </select>
            </span>
        </div>
    )
}


export default FilterResidential;