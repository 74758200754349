/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processRefund = /* GraphQL */ `
  query ProcessRefund($idResidential: String, $month: String, $year: String) {
    processRefund(idResidential: $idResidential, month: $month, year: $year)
  }
`;
export const searchMeasurements = /* GraphQL */ `
  query SearchMeasurements(
    $filter: SearchableMeasurementFilterInput
    $sort: [SearchableMeasurementSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMeasurementAggregationInput]
  ) {
    searchMeasurements(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        number
        name
        powerFactor
        electricianPower
        electricClearance
        electricalLoss
        date
        day
        month
        hour
        hourFull
        year
        createdAt
        updatedAt
        residentialMeasurementId
        thingSmartmetersMeasurementId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      name
      validated
      perfil
      dashboard
      PagesUser {
        nextToken
        __typename
      }
      TicketUser {
        nextToken
        __typename
      }
      Company {
        id
        name
        type
        createdAt
        updatedAt
        __typename
      }
      Thing {
        nextToken
        __typename
      }
      ThingsCrons {
        nextToken
        __typename
      }
      ThingsChargeEvent {
        nextToken
        __typename
      }
      Role {
        id
        name
        displayName
        icon
        createdAt
        updatedAt
        __typename
      }
      Permissions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companiesUsersId
      usersRoleId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        validated
        perfil
        dashboard
        createdAt
        updatedAt
        companiesUsersId
        usersRoleId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoles = /* GraphQL */ `
  query GetRoles($id: ID!) {
    getRoles(id: $id) {
      id
      name
      displayName
      icon
      Permissions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $id: ID
    $filter: ModelRolesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        displayName
        icon
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPermissions = /* GraphQL */ `
  query GetPermissions($id: ID!) {
    getPermissions(id: $id) {
      id
      displayName
      name
      icon
      Padre {
        id
        displayName
        name
        icon
        createdAt
        updatedAt
        permissionsSubmenuId
        __typename
      }
      Submenu {
        nextToken
        __typename
      }
      Roles {
        nextToken
        __typename
      }
      Users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      permissionsSubmenuId
      __typename
    }
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $id: ID
    $filter: ModelPermissionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPermissions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        displayName
        name
        icon
        createdAt
        updatedAt
        permissionsSubmenuId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExternalSupplier = /* GraphQL */ `
  query GetExternalSupplier($id: ID!) {
    getExternalSupplier(id: $id) {
      id
      rut
      name
      lastName
      phone
      address
      state
      bank
      typeOfAccount
      accountNumber
      email
      Thing {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listExternalSuppliers = /* GraphQL */ `
  query ListExternalSuppliers(
    $filter: ModelExternalSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExternalSuppliers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rut
        name
        lastName
        phone
        address
        state
        bank
        typeOfAccount
        accountNumber
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserAccess = /* GraphQL */ `
  query GetUserAccess($id: ID!) {
    getUserAccess(id: $id) {
      id
      idUser
      module
      date
      day
      month
      year
      hour
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserAccesses = /* GraphQL */ `
  query ListUserAccesses(
    $filter: ModelUserAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAccesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idUser
        module
        date
        day
        month
        year
        hour
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPages = /* GraphQL */ `
  query GetPages($id: ID!) {
    getPages(id: $id) {
      id
      name
      url
      ico
      type
      PagesUser {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        ico
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getParameters = /* GraphQL */ `
  query GetParameters($id: ID!) {
    getParameters(id: $id) {
      id
      type
      correlative
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listParameters = /* GraphQL */ `
  query ListParameters(
    $filter: ModelParametersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParameters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        correlative
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResumeDashboard = /* GraphQL */ `
  query GetResumeDashboard($id: ID!) {
    getResumeDashboard(id: $id) {
      id
      name
      type
      subType
      count
      isAll
      idResidence
      day
      month
      year
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listResumeDashboards = /* GraphQL */ `
  query ListResumeDashboards(
    $filter: ModelResumeDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResumeDashboards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        subType
        count
        isAll
        idResidence
        day
        month
        year
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResumeIdProcess = /* GraphQL */ `
  query GetResumeIdProcess($id: ID!) {
    getResumeIdProcess(id: $id) {
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listResumeIdProcesses = /* GraphQL */ `
  query ListResumeIdProcesses(
    $filter: ModelResumeIdProcessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResumeIdProcesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanies = /* GraphQL */ `
  query GetCompanies($id: ID!) {
    getCompanies(id: $id) {
      id
      name
      type
      Users {
        nextToken
        __typename
      }
      Residential {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompaniesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResidential = /* GraphQL */ `
  query GetResidential($id: ID!) {
    getResidential(id: $id) {
      id
      name
      address
      declaredPower
      totalParkingLots
      totalApartments
      typeOfResidence
      isSmartmeter
      buildingImage
      isListed
      isMeasurement
      hasEve
      typeOfCommunity
      contactName
      contactEmail
      residenceServiceMargin
      energicaServiceMargin
      partnerServiceMargin
      peakEnergyRate
      lowEnergyRate
      Company {
        id
        name
        type
        createdAt
        updatedAt
        __typename
      }
      PaymentTransactions {
        nextToken
        __typename
      }
      Measurement {
        nextToken
        __typename
      }
      MeasurementThing {
        nextToken
        __typename
      }
      Smartmeterv4 {
        nextToken
        __typename
      }
      ResidentialParkingLots {
        nextToken
        __typename
      }
      Refund {
        nextToken
        __typename
      }
      factor
      factorCap
      createdAt
      updatedAt
      companiesResidentialId
      __typename
    }
  }
`;
export const listResidentials = /* GraphQL */ `
  query ListResidentials(
    $filter: ModelResidentialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResidentials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        declaredPower
        totalParkingLots
        totalApartments
        typeOfResidence
        isSmartmeter
        buildingImage
        isListed
        isMeasurement
        hasEve
        typeOfCommunity
        contactName
        contactEmail
        residenceServiceMargin
        energicaServiceMargin
        partnerServiceMargin
        peakEnergyRate
        lowEnergyRate
        factor
        factorCap
        createdAt
        updatedAt
        companiesResidentialId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResidentialParkingLots = /* GraphQL */ `
  query GetResidentialParkingLots($id: ID!) {
    getResidentialParkingLots(id: $id) {
      id
      name
      parkingFloorNumber
      hasThing
      Thing {
        nextToken
        __typename
      }
      Residential {
        id
        name
        address
        declaredPower
        totalParkingLots
        totalApartments
        typeOfResidence
        isSmartmeter
        buildingImage
        isListed
        isMeasurement
        hasEve
        typeOfCommunity
        contactName
        contactEmail
        residenceServiceMargin
        energicaServiceMargin
        partnerServiceMargin
        peakEnergyRate
        lowEnergyRate
        factor
        factorCap
        createdAt
        updatedAt
        companiesResidentialId
        __typename
      }
      createdAt
      updatedAt
      residentialResidentialParkingLotsId
      thingsResidentialParkingLotsId
      __typename
    }
  }
`;
export const listResidentialParkingLots = /* GraphQL */ `
  query ListResidentialParkingLots(
    $filter: ModelResidentialParkingLotsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResidentialParkingLots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        parkingFloorNumber
        hasThing
        createdAt
        updatedAt
        residentialResidentialParkingLotsId
        thingsResidentialParkingLotsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTags = /* GraphQL */ `
  query GetTags($id: ID!) {
    getTags(id: $id) {
      id
      name
      TagsFaq {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFaqs = /* GraphQL */ `
  query GetFaqs($id: ID!) {
    getFaqs(id: $id) {
      id
      question
      answer
      TagsFaq {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFaqs = /* GraphQL */ `
  query ListFaqs(
    $filter: ModelFaqsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupportTicket = /* GraphQL */ `
  query GetSupportTicket($id: ID!) {
    getSupportTicket(id: $id) {
      id
      name
      email
      phoneNumber
      description
      idEve
      level
      EmployeeAssignedTicket {
        id
        name
        validated
        perfil
        dashboard
        createdAt
        updatedAt
        companiesUsersId
        usersRoleId
        __typename
      }
      date
      day
      month
      year
      statusTicket
      lastModificationUser
      Comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      usersTicketUserId
      __typename
    }
  }
`;
export const listSupportTickets = /* GraphQL */ `
  query ListSupportTickets(
    $filter: ModelSupportTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupportTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phoneNumber
        description
        idEve
        level
        date
        day
        month
        year
        statusTicket
        lastModificationUser
        createdAt
        updatedAt
        usersTicketUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommentTickets = /* GraphQL */ `
  query GetCommentTickets($id: ID!) {
    getCommentTickets(id: $id) {
      id
      description
      typeOfUser
      idUserAnswer
      nameUserAnswer
      ticketSupport {
        id
        name
        email
        phoneNumber
        description
        idEve
        level
        date
        day
        month
        year
        statusTicket
        lastModificationUser
        createdAt
        updatedAt
        usersTicketUserId
        __typename
      }
      clienCanSee
      createdAt
      updatedAt
      supportTicketCommentsId
      __typename
    }
  }
`;
export const listCommentTickets = /* GraphQL */ `
  query ListCommentTickets(
    $filter: ModelCommentTicketsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        typeOfUser
        idUserAnswer
        nameUserAnswer
        clienCanSee
        createdAt
        updatedAt
        supportTicketCommentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThings = /* GraphQL */ `
  query GetThings($id: ID!) {
    getThings(id: $id) {
      id
      fantasyName
      activeChargeEvent
      currentConsumedNow
      corrienteCargador
      pricePerHour
      lastCheckedIn
      state
      ResidentialParkingLots {
        nextToken
        __typename
      }
      MeasurementThing {
        nextToken
        __typename
      }
      Users {
        id
        name
        validated
        perfil
        dashboard
        createdAt
        updatedAt
        companiesUsersId
        usersRoleId
        __typename
      }
      Installer {
        id
        rut
        name
        lastName
        phone
        address
        state
        bank
        typeOfAccount
        accountNumber
        email
        createdAt
        updatedAt
        __typename
      }
      ParkingLot {
        id
        name
        parkingFloorNumber
        hasThing
        createdAt
        updatedAt
        residentialResidentialParkingLotsId
        thingsResidentialParkingLotsId
        __typename
      }
      ThingsCrons {
        nextToken
        __typename
      }
      ThingsChargeEvent {
        nextToken
        __typename
      }
      macAddress
      electricalAmperage
      electricalFase
      createdAt
      updatedAt
      usersThingId
      externalSupplierThingId
      residentialParkingLotsThingId
      __typename
    }
  }
`;
export const listThings = /* GraphQL */ `
  query ListThings(
    $id: ID
    $filter: ModelThingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listThings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fantasyName
        activeChargeEvent
        currentConsumedNow
        corrienteCargador
        pricePerHour
        lastCheckedIn
        state
        macAddress
        electricalAmperage
        electricalFase
        createdAt
        updatedAt
        usersThingId
        externalSupplierThingId
        residentialParkingLotsThingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThingsCrons = /* GraphQL */ `
  query GetThingsCrons($id: ID!) {
    getThingsCrons(id: $id) {
      id
      horaOn
      horaOff
      chargingHours
      amount
      date
      day
      month
      year
      Things {
        id
        fantasyName
        activeChargeEvent
        currentConsumedNow
        corrienteCargador
        pricePerHour
        lastCheckedIn
        state
        macAddress
        electricalAmperage
        electricalFase
        createdAt
        updatedAt
        usersThingId
        externalSupplierThingId
        residentialParkingLotsThingId
        __typename
      }
      statusCron
      Users {
        id
        name
        validated
        perfil
        dashboard
        createdAt
        updatedAt
        companiesUsersId
        usersRoleId
        __typename
      }
      createdAt
      updatedAt
      usersThingsCronsId
      thingsThingsCronsId
      __typename
    }
  }
`;
export const listThingsCrons = /* GraphQL */ `
  query ListThingsCrons(
    $filter: ModelThingsCronsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThingsCrons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        horaOn
        horaOff
        chargingHours
        amount
        date
        day
        month
        year
        statusCron
        createdAt
        updatedAt
        usersThingsCronsId
        thingsThingsCronsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThingsChargeEvent = /* GraphQL */ `
  query GetThingsChargeEvent($id: ID!) {
    getThingsChargeEvent(id: $id) {
      id
      startCharge
      endCharge
      chargingHours
      chargingKwh
      amount
      day
      month
      year
      hour
      hourFull
      Things {
        id
        fantasyName
        activeChargeEvent
        currentConsumedNow
        corrienteCargador
        pricePerHour
        lastCheckedIn
        state
        macAddress
        electricalAmperage
        electricalFase
        createdAt
        updatedAt
        usersThingId
        externalSupplierThingId
        residentialParkingLotsThingId
        __typename
      }
      status
      isActive
      type
      Users {
        id
        name
        validated
        perfil
        dashboard
        createdAt
        updatedAt
        companiesUsersId
        usersRoleId
        __typename
      }
      createdAt
      updatedAt
      usersThingsChargeEventId
      thingsThingsChargeEventId
      __typename
    }
  }
`;
export const listThingsChargeEvents = /* GraphQL */ `
  query ListThingsChargeEvents(
    $filter: ModelThingsChargeEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThingsChargeEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        startCharge
        endCharge
        chargingHours
        chargingKwh
        amount
        day
        month
        year
        hour
        hourFull
        status
        isActive
        type
        createdAt
        updatedAt
        usersThingsChargeEventId
        thingsThingsChargeEventId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThingSmartmeters = /* GraphQL */ `
  query GetThingSmartmeters($id: ID!) {
    getThingSmartmeters(id: $id) {
      id
      Measurement {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listThingSmartmeters = /* GraphQL */ `
  query ListThingSmartmeters(
    $filter: ModelThingSmartmetersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThingSmartmeters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeasurement = /* GraphQL */ `
  query GetMeasurement($id: ID!) {
    getMeasurement(id: $id) {
      id
      Residential {
        id
        name
        address
        declaredPower
        totalParkingLots
        totalApartments
        typeOfResidence
        isSmartmeter
        buildingImage
        isListed
        isMeasurement
        hasEve
        typeOfCommunity
        contactName
        contactEmail
        residenceServiceMargin
        energicaServiceMargin
        partnerServiceMargin
        peakEnergyRate
        lowEnergyRate
        factor
        factorCap
        createdAt
        updatedAt
        companiesResidentialId
        __typename
      }
      number
      name
      powerFactor
      electricianPower
      electricClearance
      electricalLoss
      date
      day
      month
      hour
      hourFull
      year
      ThingSmartmeter {
        id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      residentialMeasurementId
      thingSmartmetersMeasurementId
      __typename
    }
  }
`;
export const listMeasurements = /* GraphQL */ `
  query ListMeasurements(
    $filter: ModelMeasurementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasurements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        name
        powerFactor
        electricianPower
        electricClearance
        electricalLoss
        date
        day
        month
        hour
        hourFull
        year
        createdAt
        updatedAt
        residentialMeasurementId
        thingSmartmetersMeasurementId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSmartmeterv4 = /* GraphQL */ `
  query GetSmartmeterv4($id: ID!) {
    getSmartmeterv4(id: $id) {
      id
      number
      voltage
      current
      powerFactor
      electricianPower
      electricClearance
      electricalLoss
      date
      day
      month
      hour
      hourFull
      year
      idDevice
      Residential {
        id
        name
        address
        declaredPower
        totalParkingLots
        totalApartments
        typeOfResidence
        isSmartmeter
        buildingImage
        isListed
        isMeasurement
        hasEve
        typeOfCommunity
        contactName
        contactEmail
        residenceServiceMargin
        energicaServiceMargin
        partnerServiceMargin
        peakEnergyRate
        lowEnergyRate
        factor
        factorCap
        createdAt
        updatedAt
        companiesResidentialId
        __typename
      }
      createdAt
      updatedAt
      residentialSmartmeterv4Id
      __typename
    }
  }
`;
export const listSmartmeterv4s = /* GraphQL */ `
  query ListSmartmeterv4s(
    $filter: ModelSmartmeterv4FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSmartmeterv4s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        voltage
        current
        powerFactor
        electricianPower
        electricClearance
        electricalLoss
        date
        day
        month
        hour
        hourFull
        year
        idDevice
        createdAt
        updatedAt
        residentialSmartmeterv4Id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentTransactions = /* GraphQL */ `
  query GetPaymentTransactions($id: ID!) {
    getPaymentTransactions(id: $id) {
      id
      Residential {
        id
        name
        address
        declaredPower
        totalParkingLots
        totalApartments
        typeOfResidence
        isSmartmeter
        buildingImage
        isListed
        isMeasurement
        hasEve
        typeOfCommunity
        contactName
        contactEmail
        residenceServiceMargin
        energicaServiceMargin
        partnerServiceMargin
        peakEnergyRate
        lowEnergyRate
        factor
        factorCap
        createdAt
        updatedAt
        companiesResidentialId
        __typename
      }
      status
      token
      amount
      amountKWh
      amountEnergy
      energicaServiceMargin
      residenceServiceMargin
      partnerServiceMargin
      horaOn
      horaOff
      chargingHours
      buy_order
      card_number
      transaction_date
      accounting_date
      installments_number
      payment_type_code
      session_id
      card_detail
      installments_amount
      authorization_code
      response_code
      vci
      day
      month
      year
      hour
      glosa
      usersPaymentTransactionsId
      RefundPaymentTransactions {
        nextToken
        __typename
      }
      idEve
      idEventCharge
      hasRefund
      createdAt
      updatedAt
      residentialPaymentTransactionsId
      __typename
    }
  }
`;
export const listPaymentTransactions = /* GraphQL */ `
  query ListPaymentTransactions(
    $filter: ModelPaymentTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        token
        amount
        amountKWh
        amountEnergy
        energicaServiceMargin
        residenceServiceMargin
        partnerServiceMargin
        horaOn
        horaOff
        chargingHours
        buy_order
        card_number
        transaction_date
        accounting_date
        installments_number
        payment_type_code
        session_id
        card_detail
        installments_amount
        authorization_code
        response_code
        vci
        day
        month
        year
        hour
        glosa
        usersPaymentTransactionsId
        idEve
        idEventCharge
        hasRefund
        createdAt
        updatedAt
        residentialPaymentTransactionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayServices = /* GraphQL */ `
  query GetPayServices($id: ID!) {
    getPayServices(id: $id) {
      id
      price
      typeOfService
      name
      description
      typeOfStatus
      idCRON
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPayServices = /* GraphQL */ `
  query ListPayServices(
    $filter: ModelPayServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        price
        typeOfService
        name
        description
        typeOfStatus
        idCRON
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRefund = /* GraphQL */ `
  query GetRefund($id: ID!) {
    getRefund(id: $id) {
      id
      idTransaction
      Residential {
        id
        name
        address
        declaredPower
        totalParkingLots
        totalApartments
        typeOfResidence
        isSmartmeter
        buildingImage
        isListed
        isMeasurement
        hasEve
        typeOfCommunity
        contactName
        contactEmail
        residenceServiceMargin
        energicaServiceMargin
        partnerServiceMargin
        peakEnergyRate
        lowEnergyRate
        factor
        factorCap
        createdAt
        updatedAt
        companiesResidentialId
        __typename
      }
      RefundPaymentTransactions {
        id
        status
        token
        amount
        amountKWh
        amountEnergy
        energicaServiceMargin
        residenceServiceMargin
        partnerServiceMargin
        horaOn
        horaOff
        chargingHours
        buy_order
        card_number
        transaction_date
        accounting_date
        installments_number
        payment_type_code
        session_id
        card_detail
        installments_amount
        authorization_code
        response_code
        vci
        day
        month
        year
        hour
        glosa
        usersPaymentTransactionsId
        idEve
        idEventCharge
        hasRefund
        createdAt
        updatedAt
        residentialPaymentTransactionsId
        __typename
      }
      isPaid
      month
      year
      amountRefund
      amountKWh
      amountEnergy
      energicaServiceMargin
      residenceServiceMargin
      partnerServiceMargin
      chargingHours
      thing {
        id
        fantasyName
        activeChargeEvent
        currentConsumedNow
        corrienteCargador
        pricePerHour
        lastCheckedIn
        state
        macAddress
        electricalAmperage
        electricalFase
        createdAt
        updatedAt
        usersThingId
        externalSupplierThingId
        residentialParkingLotsThingId
        __typename
      }
      createdAt
      updatedAt
      residentialRefundId
      paymentTransactionsRefundPaymentTransactionsId
      refundThingId
      __typename
    }
  }
`;
export const listRefunds = /* GraphQL */ `
  query ListRefunds(
    $filter: ModelRefundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRefunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idTransaction
        isPaid
        month
        year
        amountRefund
        amountKWh
        amountEnergy
        energicaServiceMargin
        residenceServiceMargin
        partnerServiceMargin
        chargingHours
        createdAt
        updatedAt
        residentialRefundId
        paymentTransactionsRefundPaymentTransactionsId
        refundThingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeasurementThing = /* GraphQL */ `
  query GetMeasurementThing($id: ID!) {
    getMeasurementThing(id: $id) {
      id
      Residential {
        id
        name
        address
        declaredPower
        totalParkingLots
        totalApartments
        typeOfResidence
        isSmartmeter
        buildingImage
        isListed
        isMeasurement
        hasEve
        typeOfCommunity
        contactName
        contactEmail
        residenceServiceMargin
        energicaServiceMargin
        partnerServiceMargin
        peakEnergyRate
        lowEnergyRate
        factor
        factorCap
        createdAt
        updatedAt
        companiesResidentialId
        __typename
      }
      name
      number
      voltage
      current
      power
      date
      day
      month
      hour
      hourFull
      year
      Things {
        id
        fantasyName
        activeChargeEvent
        currentConsumedNow
        corrienteCargador
        pricePerHour
        lastCheckedIn
        state
        macAddress
        electricalAmperage
        electricalFase
        createdAt
        updatedAt
        usersThingId
        externalSupplierThingId
        residentialParkingLotsThingId
        __typename
      }
      createdAt
      updatedAt
      residentialMeasurementThingId
      thingsMeasurementThingId
      __typename
    }
  }
`;
export const listMeasurementThings = /* GraphQL */ `
  query ListMeasurementThings(
    $filter: ModelMeasurementThingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasurementThings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        number
        voltage
        current
        power
        date
        day
        month
        hour
        hourFull
        year
        createdAt
        updatedAt
        residentialMeasurementThingId
        thingsMeasurementThingId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCars = /* GraphQL */ `
  query GetCars($id: ID!) {
    getCars(id: $id) {
      id
      type
      idVehiculo
      idMarca
      nombreMarca
      idModelo
      nombreModelo
      codigoInformeTecnico
      detalle
      sistemaDistribucion
      tipoVehiculo
      tipoMotor
      combustible
      traccion
      transmision
      numeroPuertas
      capacidadAsientos
      cilindrada
      capacidadCarga
      valorMedidoNox
      rendimientoUrbano
      rendimientoCarretera
      rendimientoMixto
      rendimientoElectrico
      rendimientoPonderadoCombustible
      rendimientoPonderadoElectrico
      pesoBrutoVehicular
      distanciaEntreEjes
      anchoVehiculo
      co2
      estado
      tipoVehiculoLey
      categoria
      empresaHomologa
      norma
      propulsion
      fechaHomologacion
      fechaModificacion
      eficienciaEnergetica
      idEtiqueta
      nombreEtiqueta
      vigente
      consumos
      yearVehiculo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCars = /* GraphQL */ `
  query ListCars(
    $filter: ModelCarsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        idVehiculo
        idMarca
        nombreMarca
        idModelo
        nombreModelo
        codigoInformeTecnico
        detalle
        sistemaDistribucion
        tipoVehiculo
        tipoMotor
        combustible
        traccion
        transmision
        numeroPuertas
        capacidadAsientos
        cilindrada
        capacidadCarga
        valorMedidoNox
        rendimientoUrbano
        rendimientoCarretera
        rendimientoMixto
        rendimientoElectrico
        rendimientoPonderadoCombustible
        rendimientoPonderadoElectrico
        pesoBrutoVehicular
        distanciaEntreEjes
        anchoVehiculo
        co2
        estado
        tipoVehiculoLey
        categoria
        empresaHomologa
        norma
        propulsion
        fechaHomologacion
        fechaModificacion
        eficienciaEnergetica
        idEtiqueta
        nombreEtiqueta
        vigente
        consumos
        yearVehiculo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getElectricCars = /* GraphQL */ `
  query GetElectricCars($id: ID!) {
    getElectricCars(id: $id) {
      id
      type
      idVehiculo
      idMarca
      nombreMarca
      idModelo
      nombreModelo
      codigoInformeTecnico
      detalle
      sistemaDistribucion
      tipoVehiculo
      tipoMotor
      combustible
      traccion
      transmision
      numeroPuertas
      capacidadAsientos
      cilindrada
      capacidadCarga
      valorMedidoNox
      rendimientoUrbano
      rendimientoCarretera
      rendimientoMixto
      rendimientoElectrico
      rendimientoPonderadoCombustible
      rendimientoPonderadoElectrico
      pesoBrutoVehicular
      distanciaEntreEjes
      anchoVehiculo
      co2
      estado
      tipoVehiculoLey
      categoria
      empresaHomologa
      norma
      propulsion
      fechaHomologacion
      fechaModificacion
      eficienciaEnergetica
      idEtiqueta
      nombreEtiqueta
      vigente
      consumos
      yearVehiculo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listElectricCars = /* GraphQL */ `
  query ListElectricCars(
    $filter: ModelElectricCarsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElectricCars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        idVehiculo
        idMarca
        nombreMarca
        idModelo
        nombreModelo
        codigoInformeTecnico
        detalle
        sistemaDistribucion
        tipoVehiculo
        tipoMotor
        combustible
        traccion
        transmision
        numeroPuertas
        capacidadAsientos
        cilindrada
        capacidadCarga
        valorMedidoNox
        rendimientoUrbano
        rendimientoCarretera
        rendimientoMixto
        rendimientoElectrico
        rendimientoPonderadoCombustible
        rendimientoPonderadoElectrico
        pesoBrutoVehicular
        distanciaEntreEjes
        anchoVehiculo
        co2
        estado
        tipoVehiculoLey
        categoria
        empresaHomologa
        norma
        propulsion
        fechaHomologacion
        fechaModificacion
        eficienciaEnergetica
        idEtiqueta
        nombreEtiqueta
        vigente
        consumos
        yearVehiculo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPagesUser = /* GraphQL */ `
  query GetPagesUser($id: ID!) {
    getPagesUser(id: $id) {
      id
      usersID
      pagesID
      users {
        id
        name
        validated
        perfil
        dashboard
        createdAt
        updatedAt
        companiesUsersId
        usersRoleId
        __typename
      }
      pages {
        id
        name
        url
        ico
        type
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPagesUsers = /* GraphQL */ `
  query ListPagesUsers(
    $filter: ModelPagesUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPagesUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usersID
        pagesID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPermissionsUsers = /* GraphQL */ `
  query GetPermissionsUsers($id: ID!) {
    getPermissionsUsers(id: $id) {
      id
      usersID
      permissionsID
      users {
        id
        name
        validated
        perfil
        dashboard
        createdAt
        updatedAt
        companiesUsersId
        usersRoleId
        __typename
      }
      permissions {
        id
        displayName
        name
        icon
        createdAt
        updatedAt
        permissionsSubmenuId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPermissionsUsers = /* GraphQL */ `
  query ListPermissionsUsers(
    $filter: ModelPermissionsUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissionsUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersID
        permissionsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPermissionsRoles = /* GraphQL */ `
  query GetPermissionsRoles($id: ID!) {
    getPermissionsRoles(id: $id) {
      id
      rolesID
      permissionsID
      roles {
        id
        name
        displayName
        icon
        createdAt
        updatedAt
        __typename
      }
      permissions {
        id
        displayName
        name
        icon
        createdAt
        updatedAt
        permissionsSubmenuId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPermissionsRoles = /* GraphQL */ `
  query ListPermissionsRoles(
    $filter: ModelPermissionsRolesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissionsRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rolesID
        permissionsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTagsFaq = /* GraphQL */ `
  query GetTagsFaq($id: ID!) {
    getTagsFaq(id: $id) {
      id
      tagsID
      faqsID
      tags {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      faqs {
        id
        question
        answer
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTagsFaqs = /* GraphQL */ `
  query ListTagsFaqs(
    $filter: ModelTagsFaqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTagsFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tagsID
        faqsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
