/* eslint-disable no-useless-computed-key */
import AdminDashboard from './admin';
import BasicDashboard from './basic';
import BuildingDashboard from './building';
import TestDashboard from './test';
import DealerCarDashboard from './dealerCar';
import OwnerCarDashboard from './ownerCar';
import RealStateDashboard from './realState';
import MeasurementPage from "./measurement/";


// # basic      #Dashboard para y usuario sin privilegios
// # admin     #Dashboard para Admin energica
// # building  #Dashboard para administrador de edificios
// # dealerCar #Dashboard para Dealer que venden vehículos
// # ownerCar  #Dashboard para dueños de Vehículo
// # realState #Dashboard para Inmobiliarias


export const Dashboard = (props) => {

    const typeOfComponents = {
        ["measurement"]: MeasurementPage,
        ["basic"]: BasicDashboard,
        ["admin"]: AdminDashboard,
        ["building"]: BuildingDashboard,
        ["dealerCar"]: DealerCarDashboard,
        ["ownerCar"]: OwnerCarDashboard,
        ["realState"]: RealStateDashboard,
        ["test"]: TestDashboard,
    };

    const TypeOfDashboard = typeOfComponents[props.typeOfDashboard] || BasicDashboard;

    return <TypeOfDashboard {...props} />;
};
