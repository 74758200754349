import { SET_PROFILE } from './constants';

const initialState = {
    pages:[],
    name:'',
};

export const profile = (state = initialState, action) => {
    switch (action.type) {

        case SET_PROFILE: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
};
export default profile;