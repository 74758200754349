import React from "react";
import SectionTitle from '../../../components/section-title';
import SearchList from './SearchList';
import ViewResidential from '../../../components/viewResidential';

// const MeasurementPage = lazy(() => import("../../measurement"));

// # basic      #Dashboard para y usuario sin privilegios
// # admin     #Dashboard para Admin energica
// # ownerCar  #Dashboard para dueños de Vehículo
// # realState #Dashboard para Inmobiliarias
// # building  #Dashboard para administrador de edificios
// # dealerCar #Dashboard para Dealer que venden vehículos


const BuildingDashboard = (props) => {

    return (
        <>

            {/* <pre>companiesId={JSON.stringify(props, 2, null)}</pre> */}
            <SectionTitle title="Bienvenido" subtitle="Administrador de edificio" />
            <SearchList
                companiesUsersId={props.companiesUsersId}
            >
                <ViewResidential />
                {/* <GraphGenerate /> */}
            </SearchList>
        </>
    )
}

export default BuildingDashboard;