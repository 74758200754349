import axios from 'axios';
import { xForwarded } from "./xforwardedList";

axios.defaults.baseURL = './api';

export const fetchAxios = async (fetchParams) => {
    let response =undefined;
    let error='';
    let loading = true;

    const fetchData = async (params) => {
        try{
            const result = await axios.request(
                {
                    ...params,
                }
            );
            
            // console.log('--fetchAxios--', result)
            const res = await result.data;
            response ={ ...res, status: result.status, statusText: result.statusText }
            
        }catch( error ){
            // console.log('--fetchAxios--', error)
            error = error;
            response ={ status: 400, statusText: '' }
        }finally{
            loading = false;
        }
        
    };
    
    const headerExtra = { headers: { ...xForwarded,  ...fetchParams.headers }};
    // console.log('---AXIOS-FETCH--', headerExtra);
    await fetchData({...fetchParams, ...headerExtra});

    return { response, error, loading };
};

// Verify that the TOKEN IS VALID
export const validTokenFetch = async (fetchParams, infoToken={}) => {

    const instance = axios.create();
    instance.interceptors.request.use( (config)=>{
        // TODO:  Validar vigencia de token 
        
        
        // config.headers.test = 'I am only a header!';
        console.log('--interceptors--', config)
        return config;

    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });

    let response =undefined;
    let error='';
    let loading = true;

    const fetchData = async (params) => {
        try{
            const result = await instance.request(params);
            
            response = await result.data;

        }catch( error ){
            error = error;
        }finally{
            loading = false;
        }

    };
    
    await fetchData(fetchParams);

    return { response, error, loading };
};