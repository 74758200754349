import React from 'react'

const List2 = () => {
  const items = [
    {
      title: 'Crear EVe',
      subtitle: 'Instructivo paso a paso como crear un EVe'
    },
    {
      title: 'Crear Usuario de la APP',
      subtitle: 'Instructivo paso a paso como crear un usuario de la App'
    },
    // {
    //   title: 'Crear Usuario de la ',
    //   subtitle: 'Instructivo paso a paso como crear un '
    // },
    // {
    //   title: '2 new items',
    //   subtitle: 'Instructivo paso a paso como crear un '
    // },
    // {
    //   title: '51 registered users',
    //   subtitle: 'Instructivo paso a paso como crear un '
    // }
  ]
  return (
    <div className="flex flex-row flex-wrap">
      {items.map((item, i) => (
        <div key={i} className="w-full">
          <div
            className="flex items-center justify-start dropdown-item p-2"
            key={i}>
            <div className="ml-2">
              <div className="text-sm font-bold">{item.title}</div>
              <div className="text-xs">{item.subtitle}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default List2
