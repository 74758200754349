export const onUpdateThings = /* GraphQL */ `
  subscription OnUpdateThings {
    onUpdateThings {
      id
      activeChargeEvent
      autonomia
      capacidadBateria
      corrienteCargador
      lastCheckedIn
      state
      macAddress
      createdAt
      updatedAt
      usersThingId
    }
  }
`;