// import React from "react";
// import SectionTitle from '../../../components/section-title';
// import SearchList from './SearchList';
// import ViewResidential from '../../../components/viewResidential';

// // const MeasurementPage = lazy(() => import("../../measurement"));

// // # basic      #Dashboard para y usuario sin privilegios
// // # admin     #Dashboard para Admin energica
// // # ownerCar  #Dashboard para dueños de Vehículo
// // # realState #Dashboard para Inmobiliarias
// // # building  #Dashboard para administrador de edificios
// // # dealerCar #Dashboard para Dealer que venden vehículos


// const TestDashboard = (props) => {

//     return (
//         <>

//             {/* <pre>companiesId={JSON.stringify(props, 2, null)}</pre> */}
//             <SectionTitle title="Bienvenido" subtitle="Usuario de test" />
//             <SearchList
//                 companiesUsersId={props.companiesUsersId}
//             >
//                 {/* <ViewResidential /> */}
//                 {/* <GraphGenerate /> */}
//             </SearchList>
//         </>
//     )
// }

import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from "aws-amplify";

import { listThingsStatus } from "../../../graphql/queries_custom";
import { onUpdateThings } from '../../../graphql/subscriptions_custom';
import AppListEves from './appListEves'
import Section from '../../../components/dashboard/section'
import SectionTitle from '../../../components/dashboard/section-title'

// const idEve = "EVE-MOC";

const TestDashboard = () => {

    const [things, setThings] = useState([]);
    const [thing, setThing] = useState('');
    console.log('--things--', things)

    const getAPIThings = async () => {
        try {
            const filterEve = thing === '' ? {} : { id: { eq: thing } };
            const filterDetail = { filter: { ...filterEve } };


            const listQuery = await API.graphql({
                query: listThingsStatus,
                variables: { ...filterDetail }
            });
            console.log('---filterDetail---', filterDetail)
            console.log('---listQuery.data.listThings.items---', listQuery.data.listThings.items)
            // const orderItems = listQuery.data.listThings.items.sort((a, b) => a - b)
            // setThings(orderItems);
            setThings(listQuery.data.listThings.items);


        } catch (err) {
            console.log({ err });
        }
    }

    const handlerOnSubmit = (e) => {
        e.preventDefault();
        getAPIThings();
    }

    useEffect(() => {
        const changeInservice = API.graphql(
            graphqlOperation(onUpdateThings, {})
        ).subscribe({
            next: ({ provider, value }) => getAPIThings(),
            error: error => console.warn(error)
            //next: ({ provider, value }) => {
            // my code ...
            //},
            //error: (error) => console.log("update inservice ", error.error.errors[0]),
        });

        return () => changeInservice.unsubscribe();    // <== add this one
    }, []);

    useEffect(() => getAPIThings(), []);

    return (
        <>
            <SectionTitle title="Sección" subtitle={"Eves"} right={false} state={{ user: {} }} />
            <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
                <Section
                    title={`Listado de ${"Eves"}`}
                    description={<span></span>}
                    right={null}
                >
                    <div className="flex flex-col w-full">

                        <form onSubmit={handlerOnSubmit} className="mb-6">
                            {/* <span>thing = {thing}</span> */}
                            <div className="flex flex-col sm:flex-row justify-start">
                                <div className="w-64 flex flex-col sm:flex-row">
                                    <span className="sm:py-4 sm:pr-2">
                                        <span className="text-sm font-bold sm:visible text-grey-500">Eve:</span>
                                        <select
                                            name={'thingList'}
                                            value={thing}
                                            className='form-select w-full mr-4 mb-4'
                                            onChange={(e) => setThing(e.target.value)}
                                        >
                                            <option key={'THING-DEFAULTVALUE'} value={''}>-</option>
                                            {
                                                things.map((option, j) => (
                                                    <option key={j} value={option.id}>
                                                        {option.id}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </span>
                                </div>

                                <div className="w-32 flex flex-row items-center">
                                    <span className="py-4 sm:pr-2 ">
                                        <input
                                            type="submit"
                                            value="FILTRAR"
                                            className="w-full px-4 py-2 ml-4 bg-energica-red hover:bg-red-500 text-white font-bold rounded-lg text-center"
                                        />
                                    </span>
                                </div>

                            </div>
                        </form>

                    </div>

                    <div className="flex flex-col w-full">
                        <div className="overflow-x-scroll lg:overflow-hidden">
                            <AppListEves items={things} />
                        </div>
                    </div>
                </Section>
            </div>
        </>

    )
}

export default TestDashboard;