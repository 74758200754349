import React from 'react';
import Login from '../components/amplify/LoginAmplify';

const LoginPage = () => {
  return (
    <>
      <div className="w-full flex flex-row h-screen overflow-hidden">
        <div className="hidden lg:flex lg:flex-col w-2/3 items-center justify-center from-white ">
          {/* bg-auto bg-no-repeat bg-start `` */}
          <img
            // className="object-contain w-auto mb-8 w-2/4"
            className="object-fill w-full"
            src="/images/bg-cars.png"
            alt="Energica"
          />
        </div>
        <div className="w-full lg:w-1/2 bg-white flex flex-col items-start justify-center p-6 lg:px-15">
          <div className="flex flex-col w-full mb-4">
            <img
              className="object-none h-48 w-full  md:object-none mt-8 "
              src="/logos/iso.svg"
              alt="Energica"
            />
            <div className="text font-bold mb-2 flex justify-center items-center">
              <Login className="flex justify-center items-center" />
            </div>
          </div>

          <div className='w-full mt-4 text-center'>
            <span className='text-gray-50'>Versión 1.1</span>
          </div>


        </div>
      </div>
    </>
  );
};

export default LoginPage;
