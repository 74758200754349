import {combineReducers} from 'redux'
import config from './config'
import leftSidebar from './left-sidebar'
import palettes from './palettes'
import navigation from './navigation'

import profile from './profile/reducer';
import auth from './auth/reducer';

const rootReducer = combineReducers({
  navigation,
  config,
  leftSidebar,
  palettes,
  auth,
  profile,
})

export default rootReducer
