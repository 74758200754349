import React, { useState } from 'react';
import { FiEye, FiToggleRight, FiToggleLeft, FiChevronsRight, FiActivity, FiClock } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import axios from "axios";
import { ElectricCar } from '../../../components/assets/electricCar';


import LoaderWait from '../../../components/loaderWait';
import { Badge } from '../../../components/badges'

const TIME_OFFLINE = 10;
const URL_API = "https://y1lvayovld.execute-api.us-east-2.amazonaws.com/PROD/status";


const typeOfColor = {
  // eslint-disable-next-line no-useless-computed-key
  [""]: "text-grey-300",
  // eslint-disable-next-line no-useless-computed-key
  ["on"]: "text-green-400",
  // eslint-disable-next-line no-useless-computed-key
  ["off"]: "text-blue-400",
  // eslint-disable-next-line no-useless-computed-key
  ["offline"]: "text-red-400",
};
const typeOfColorHexadecimal = {
  // eslint-disable-next-line no-useless-computed-key
  [""]: "text-grey-300",
  // eslint-disable-next-line no-useless-computed-key
  ["on"]: "rgba(102, 187, 106, 1)",
  // eslint-disable-next-line no-useless-computed-key
  ["off"]: "rgba(66, 165, 245, 1)",
  // eslint-disable-next-line no-useless-computed-key
  ["offline"]: "rgba(239, 83, 80, 1)",
};
const typeOfName = {
  // eslint-disable-next-line no-useless-computed-key
  [""]: "",
  // eslint-disable-next-line no-useless-computed-key
  ["on"]: "Cargando",
  // eslint-disable-next-line no-useless-computed-key
  ["off"]: "Disponible",
  // eslint-disable-next-line no-useless-computed-key
  ["offline"]: "Offline",
  ["habilitado"]: "Cargador habilitado",
};

const typeOfStatusCE = {
  // eslint-disable-next-line no-useless-computed-key
  [""]: "",
  // eslint-disable-next-line no-useless-computed-key
  ["charge_started_confirmed_by_eve"]: "bg-yellow-100 text-yellow-700",
  // eslint-disable-next-line no-useless-computed-key
  ["charge_started"]: "bg-blue-100 text-blue-600",
  // eslint-disable-next-line no-useless-computed-key
  ["time_out"]: "bg-red-100 text-red-400",
  // eslint-disable-next-line no-useless-computed-key
  ["charge_finished"]: "bg-grey-400 text-grey-800",
};






const AppListEves = ({ items, isLoading }) => {
  const [state, setState] = useState({ status: false, eve: "" })

  console.log('---items---', items)
  // const filterThing = (idThing) => things.filter((i) => i.id === idThing)
  // const itemsSort = () => items.sort((x, y) => { return x - y })
  // item?.usersThingId

  const fetchAPI = async (data) => {
    setState({ status: true, eve: data.IdEve })


    // console.log('--data--', data.eventCharges)

    const filterEventCharge = data.eventCharges.filter((item) => item.isActive)


    const dataEve = {
      "status": data.status,
      "idD": data.IdEve,
      "evntChId": filterEventCharge[0]?.id || 0,
      "chgMin": data?.min || 0,
    };
    console.log("---dataEve--", dataEve);

    let response = await axios.post(
      `${URL_API}`, JSON.stringify(dataEve));

    console.log("---response--", response.data.data);
    setState({ status: false, eve: "" })
  }

  return (
    <>
      <div className=" flex flex-row flex-wrap w-full mb-4 justify-start">
        {
          items.length === 0 ? (isLoading ? <LoaderWait /> : (<span className='text-energica-red text-lg'><span className='mr-4 text-3xl'>😴</span>Sin registros encontrados</span>))
            : (
              items.sort((a, b) => a?.usersThingId?.localeCompare(b?.usersThingId)).map((item, i) => {

                console.log('-->item', item)
                const diffTime = Math.abs(new Date(item.updatedAt) - new Date());
                const minutes = Math.floor((diffTime / 1000) / 60);

                return (
                  <>
                    <div className={`flex flex-col justify-center border border-grey-200 p-2 my-6 mx-6 w-96 h-full min-w-min min-h-min text-grey `}>
                      <div className={`bg-gray-300 flex flex-col items-center justify-center p-4`}>
                        <p className="text-sm font-light text-grey-500">
                          {item?.usersThingId || ""}
                        </p>
                        <p className="text-sm font-light text-grey-500">
                          <span className="text-grey-900">{item.id}</span>
                        </p>
                        <div className={`bg-gray-300 flex flex-row items-center justify-between p-4`}>
                          <ElectricCar color={`${minutes >= TIME_OFFLINE ? typeOfColorHexadecimal["offline"] : typeOfColorHexadecimal[item?.state || ""]}`} />
                          <p className={`w-24 ml-4 text-lg font-light ${minutes >= TIME_OFFLINE ? typeOfColor["offline"] : typeOfColor[item?.state || ""]}`} >
                            {
                              Number(minutes) >= Number(TIME_OFFLINE) ? (
                                //OFFLINE
                                typeOfName["offline"]
                              ) : (
                                item.state === "on" ? (
                                  // ON
                                  // CARGANDO
                                  item?.currentConsumedNow > 0 || 0 ? (
                                    typeOfName[item?.state || ""]
                                  ) : (
                                    // CARGADOR HABILITADO (NO HAY CONSUMO)
                                    typeOfName["habilitado"]
                                  )

                                ) : (
                                  //OFF
                                  typeOfName[item?.state || ""]
                                )
                              )
                            }
                          </p>
                        </div>
                        {minutes > 0 ? <span>{Number(minutes)} min. sin responder</span> : null}

                        {item?.Things?.currentConsumedNow > 0 ? <span>Corriente: {Number(item?.Things?.currentConsumedNow).toFixed(1)} A</span> : null}
                      </div>
                      <div className={`flex flex-row justify-evenly  border-t border-grey-200 `}>
                        <div className="flex flex-row py-2 px-2">
                          <Link
                            to={{
                              pathname: "/detail-eve",
                              state: { thing: item.id }
                            }}
                            className="flex py-4 px-2 mr-4 rounded flex-row justify-center items-center btn btn-rounded bg-grey-100 hover:bg-grey-300 text-grey-600">
                            <FiActivity color={'#464646'} className="mx-2 my-2mb-2" size={24} />
                            <span>Mediciones</span>
                          </Link>
                          <Link
                            to={{
                              pathname: "/graph-eve",
                              state: { thing: item.id }
                            }}
                            className="flex py-4 px-2 mr-4 rounded flex-row justify-center items-center btn btn-rounded bg-grey-100 hover:bg-grey-300 text-grey-600">
                            <FiEye color={'#464646'} className="mx-2 my-2mb-2" size={24} />
                            <span>Consumo</span>
                          </Link>




                        </div >

                      </div >



                    </div >
                  </>
                )
              })
            )
        }
      </div >

      <div div className="w-full flex justify-start mb-8" >
        <div className="flex flex-row items-center justify-center h-10">
          <span className="text-grey-500 text-energica-red "><FiChevronsRight /></span>
          <span className="text-3xl text-energica-red mx-2">{items.length}</span>
          <span className="text-grey-500 text-energica-red ">registros visualizados</span>
        </div>
      </div>
    </>
  )
}

export default AppListEves
