import React from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
// import { useAuthenticator } from '@aws-amplify/ui-react';
import {FiMenu} from 'react-icons/fi';
// import {FiSettings, FiMenu} from 'react-icons/fi';
import DropdownAlert from './dropdownAlert';
import DropdownUser from './dropdownUser';
import Search from './search';
import '../../css/components/navbar.css';

const Navbar = ({getSignOut}) => {
  // const { user, signOut } = useAuthenticator((context) => [context.user]);

  const {config} = useSelector(
    state => ({
      config: state.config
    }),
    shallowEqual
  )
  let {collapsed} = { ...config }
  // let {rightSidebar, collapsed} = { ...config }
  const dispatch = useDispatch()
  return (
    <div className="navbar navbar-1 border-b pr-3">
      <div className="navbar-inner w-full flex items-center justify-start">
        <button
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'collapsed',
              value: !collapsed
            })
          }
          className="mx-4">
          <FiMenu size={20} />
        </button>
        <Search />
        <span className="ml-auto"></span>
        <DropdownAlert />
        <DropdownUser getSignOut={getSignOut}/>
        {/* <span> {user.attributes.email}</span> */}
        {/* <button
          className="btn-transparent flex items-center justify-center h-16 w-8 mx-4"
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'rightSidebar',
              value: !rightSidebar
            })
          }>
          <FiSettings size={18} />
        </button> */}
      </div>
    </div>
  )
}

export default Navbar
