import { SET_AUTH_USER, SET_AUTH_CHANGE_PASSWORD } from './constants';

const initialState = {
    user:{challengeName:''},
    // token:'',
    id:null,
    isAuthenticated:false,
    messageAuthCognito: ''
};

export const auth = (state = initialState, action) => {
    switch (action.type) {

        case SET_AUTH_USER: {
            return {
                ...state,
                ...action.payload
            };
        }
        case SET_AUTH_CHANGE_PASSWORD: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
};
   
export default auth;