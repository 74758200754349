import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";

import Loader from "./components/loader";


import { Dashboard } from './pages/dashboard/index';

const EmptyPage = lazy(() => import("./pages/empty-page"));
// const DashboardPage = lazy(() => import("./pages/dashboardPage"));
const UserslistPage = lazy(() => import("./pages/userslist/"));
const UsersDetail = lazy(() => import("./pages/usersDetail"));
const UserProfile = lazy(() => import("./pages/userProfile/"));
// const SimulateListPage = lazy(() => import("./pages/simulateList/"));
const SmartmeterPage = lazy(() => import("./pages/smartMeterList/"));
const MeasurementPage = lazy(() => import("./pages/measurement/"));
const AppResidentialList = lazy(() => import("./pages/ResidentialList/"));
const Transactions = lazy(() => import("./pages/transactions/"));
const RefundPage = lazy(() => import("./pages/refund"));

const ReportListPage = lazy(() => import('./pages/reportList'))
const AppUserList = lazy(() => import('./pages/appUserList/'))
const AppEveList = lazy(() => import('./pages/appEveList/'))

const AppEveCron = lazy(() => import('./pages/appEveCron/'))
const AppCronEventCharge = lazy(() => import('./pages/appEveCron/eventCharge'))

const MeasurementEvesPage = lazy(() => import('./pages/appEveList/measurement'))
const RealtimeEvePage = lazy(() => import('./pages/appEveList/realtimeEve'))
const Faqs = lazy(() => import('./pages/Faqs'))
const Support = lazy(() => import('./pages/Support'))
// const MeasurementEveList = lazy(() => import('./pages/appEveList/measurementEveList'))

const AppCarsList = lazy(() => import('./pages/appCarsList'))
const CompaniesListPage = lazy(() => import('./pages/companiesList/'))
// DASHBOARD
// none      #Dashboard para y usuario sin privilegios
// admin     #Dashboard para Admin energica
// ownerCar  #Dashboard para dueños de Vehículo
// realState #Dashboard para Inmobiliarias
// building  #Dashboard para administrador de edificios
// dealerCar #Dashboard para Dealer que venden vehículos

const Routes = (props) => {
  // const { typeOfDashboard, companiesId } = props;

  return (
    <Suspense fallback={<Loader />}>
      {/* <pre>{JSON.stringify(props.typeOfDashboard, 2, null)}</pre> */}
      <Switch>
        <Route exact path="/">
          <Dashboard {...props} />
        </Route>

        <Route exact path="/users" component={UserslistPage} />
        <Route exact path="/user" component={UsersDetail} />
        <Route exact path="/user-profile" component={UserProfile} />
        <Route exact path="/report" component={ReportListPage} />
        <Route exact path="/transactions" component={Transactions} />
        <Route exact path="/refund">
          <RefundPage {...props} />
        </Route>
        {/* <Route exact path="/refund" component={RefundPage} /> */}
        {/* <Route exact path="/simulates" component={SimulateListPage} /> */}
        <Route exact path="/smartmeters" component={SmartmeterPage} />
        <Route exact path="/measurement" component={MeasurementPage} />

        <Route exact path="/app-users" component={AppUserList} />
        <Route exact path="/app-eve" component={AppEveList} />
        <Route exact path="/cron" component={AppEveCron} />
        <Route exact path="/event-charge" component={AppCronEventCharge} />
        <Route exact path="/detail-eve" component={MeasurementEvesPage} />
        <Route exact path="/graph-eve" component={RealtimeEvePage} />
        <Route exact path="/faqs" component={Faqs} />
        <Route exact path="/support" component={Support} />
        {/* <Route exact path="graph-eve" element={<DetailEveList />} /> */}

        {/* <Route exact path="/app-eve" element={<AppEveList />}>
          <Route exact path="detail" element={<DetailEveList />} />
        </Route> */}

        <Route exact path="/app-cars" component={AppCarsList} />
        <Route exact path="/app-residential" component={AppResidentialList} />
        <Route exact path="/app-companies" component={CompaniesListPage} />

        <Route component={EmptyPage} />
      </Switch>
    </Suspense>
  );
};
export default Routes;
