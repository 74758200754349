import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

// import { getProfile } from '../../store/profile/actions';

import Item from './item';
import Logo from './logo';
import '../../css/components/left-sidebar-1.css';

const Sidebar = () => {
  // const dispatch = useDispatch();
  const { profile, profile: { pages }, profile: { name } } = useSelector(state => state);

  const { navigation } = useSelector(
    (state) => ({
      navigation: state.navigation
    }),
    shallowEqual
  )

  return (
    <div className="left-sidebar left-sidebar-1">
      <Logo />
      <div>
        <div className="left-sidebar-title"></div>

        <ul>
          <li key={`00-home`} className="l0">
            <Item
              url={'/'}
              icon={'FiHome'}
              title={'Inicio'}
              items={[]}
            />
          </li>
          <div className="left-sidebar-title text-grey-500 border-t"><span></span></div>
          {pages.map((item, i) => {
            return item.pages.type === 'web' ? (
              <li key={`ITEM${i}${item.id}`} className="l0">
                <Item
                  url={item.pages.url}
                  icon={item.pages.ico}
                  title={item.pages.name}
                  items={[]}
                />
              </li>
            ) : null
          }
          )}

          <div className="left-sidebar-title text-grey-500 border-t"><span></span></div>

          {pages.map((item, i) => {
            return item.pages.type === 'mobile' ? (
              <li key={`ITEM${i}${item.id}`} className="l0">
                <Item
                  url={item.pages.url}
                  icon={item.pages.ico}
                  title={item.pages.name}
                  items={[]}
                />
              </li>
            ) : null
          }
          )}

          <div className="left-sidebar-title text-grey-500 border-t"><span></span></div>

          {pages.map((item, i) => {
            return item.pages.type === 'pay' ? (
              <li key={`ITEM${i}${item.id}`} className="l0">
                <Item
                  url={item.pages.url}
                  icon={item.pages.ico}
                  title={item.pages.name}
                  items={[]}
                />
              </li>
            ) : null
          }
          )}

        </ul>
      </div>

    </div>
  )
}

export default Sidebar
