export const listPaymentEventCharge = /* GraphQL */ `
query listPaymentTransactions{
    query listPaymentTransactions(
      $filter: ModelSupportTicketFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listPaymentTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      status
      token
      amount
      buy_order
      card_number
      transaction_date
      accounting_date
      installments_number
      payment_type_code
      session_id
      card_detail
      installments_amount
      authorization_code
      response_code
      vci
      day
      month
      year
      hour
      horaOn
      horaOff
      chargingHours
      glosa
      idEve
      idEventCharge
      createdAt
      updatedAt
      usersPaymentTransactionsId
    }
    nextToken
  }
}
`;

export const listSupportTickets = /* GraphQL */ `
query listSupportTickets(
  $filter: ModelSupportTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  listSupportTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      phoneNumber
      description
      idEve
      level
      date
      day
      month
      year
      statusTicket
      lastModificationUser
      createdAt
      updatedAt
    }
  }
}
`;
export const getThingsCharge = /* GraphQL */ `
query getThingsCharge($id: ID!) {
  getThings(id: $id) {
    id
    state
    ThingsChargeEvent
    #(
      #filter: { isActive: { eq: true } }
    #)
    {
    items{
      id
      status
      isActive
      startCharge
			endCharge
			chargingHours
      day
      month
      year
      hourFull
      status
      isActive
      type
      chargingKwh
      Users{
        id
      }
    }
    }
  }
}
`;


export const getResidential = /* GraphQL */ `
  query GetResidential($id: ID!) {
    getResidential(id: $id) {
      id
      name
      address
      declaredPower
      totalParkingLots
      totalApartments
      typeOfResidence
      isSmartmeter
      buildingImage
      residenceServiceMargin
      energicaServiceMargin
      Company {
        id
        name
        type
        Users {
          nextToken
        }
        Residential {
          nextToken
        }
        createdAt
        updatedAt
      }
      Measurement {
        items {
          id
          number
          name
          powerFactor
          electricianPower
          electricClearance
          electricalLoss
          date
          day
          month
          hour
          hourFull
          year
          createdAt
          updatedAt
          thingSmartmetersMeasurementId
          residentialMeasurementId
        }
        nextToken
      }
      MeasurementThing {
        items {
          id
          name
          number
          voltage
          current
          power
          date
          day
          month
          hour
          hourFull
          year
          createdAt
          updatedAt
          thingsMeasurementThingId
          residentialMeasurementThingId
        }
        nextToken
      }
      Smartmeterv4 {
        items {
          id
          number
          voltage
          current
          powerFactor
          electricianPower
          electricClearance
          electricalLoss
          date
          day
          month
          hour
          hourFull
          year
          idDevice
          createdAt
          updatedAt
          residentialSmartmeterv4Id
        }
        nextToken
      }
      ResidentialParkingLots {
        items {
          id
          name
          hasThing
          createdAt
          updatedAt
          thingsResidentialParkingLotsId
          residentialResidentialParkingLotsId
          Things{
            id
            state
            currentConsumedNow
            updatedAt
          }
        }
        nextToken
      }
      factor
      factorCap
      createdAt
      updatedAt
      companiesResidentialId
    }
  }
`;

export const listResidentialsStatusEve = /* GraphQL */ `
  query ListResidentials(
    $filter: ModelResidentialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResidentials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        declaredPower
        totalParkingLots
        totalApartments
        typeOfResidence
        isSmartmeter
        buildingImage
        residenceServiceMargin
        energicaServiceMargin
        
        typeOfCommunity
        contactName
        contactEmail
        
        Company {
          id
          name
          type
          createdAt
          updatedAt
        }
        Measurement {
          nextToken
        }
        MeasurementThing {
          nextToken
        }
        Smartmeterv4 {
          nextToken
        }
        ResidentialParkingLots {
          items{
            thingsResidentialParkingLotsId
            id
            name
            Things{
              id
              state
              updatedAt
            } 
          }
        }
        factor
        factorCap
        createdAt
        updatedAt
        companiesResidentialId
      }
      nextToken
    }
  }
`;

export const listSmartmeterv4s = /* GraphQL */ `
  query ListSmartmeterv4s(
    $filter: ModelSmartmeterv4FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSmartmeterv4s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        voltage
        current
        electricianPower
        powerFactor
        electricClearance
        electricalLoss
        date
        day
        month
        year
        hour
        hourFull
        idDevice
      }
      nextToken
    }
  }
`;

export const getUsersWithID = /* GraphQL */ `
query GetUsers($id: ID!) {
  getUsers(id: $id) {
    id
    name
    validated
    perfil
    Company {
      id
      name
      type
      Users {
        nextToken
      }
      createdAt
      updatedAt
    }
    PagesUser {
      items {
        id
        createdAt
        pagesID
        updatedAt
        usersID
        pages{
          id
          name
          url
          ico
          type
        }
      }
    }
    createdAt
    updatedAt
    companiesUsersId
  }
}
`;

export const listUsers = /* GraphQL */ `
query QueryListUsers {
  listUsers {
    items {
      id
      name
      validated
      perfil
      Company {
        id
        name
        type
        createdAt
        updatedAt
      }
      
    PagesUser {
      items {
        id
        createdAt
        pagesID
        updatedAt
        usersID
        pages{
          id
          name
          url
          ico
        }
      }
    }
      createdAt
      updatedAt
      companiesUsersId
    }
    nextToken
  }
}
`;

export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      name
      validated
      perfil
      dashboard
      Company {
        id
        name
        type
        Users {
          nextToken
        }
        createdAt
        updatedAt
      }
      PagesUser {
        items {
          id
          createdAt
          pagesID
          updatedAt
          usersID
          pages{
            id
            name
            url
            ico
            type
          }
        }
      }
      createdAt
      updatedAt
      companiesUsersId
    }
  }
`;



export const listPages = `
query QueryListPages {
  listPages {
    items {
      id
      name
      url
      ico
      createdAt
      updatedAt
    }
    nextToken
  }
}`


//USER - PAGES 
export const removePageUser = `
  mutation MutationDeletePageUser($id: ID!) {
    deletePageUsers(input: {id: $id }) {
      id
    }
}`



// SMARTMETER V3

export const listSmartmeterv3s = `
query ListSmartmeterv3s(
  $filter: ModelSmartmeterv3FilterInput
  $limit: Int
  $nextToken: String
) {
  listSmartmeterv3s(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      day
      month
      year
      current
      number
      power
      idDevice
      voltage
    }
    nextToken
  }
}
`;

export const listCompanies = `
query QueryListCompanies
{
  listCompanies {
    items {
      id
      name
      type
      Residential{
        items{
          name
        }
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const listResidentials = `
query QueryListResidentials {
  listResidentials {
    items {
      id
      name
      address
      declaredPower
      totalParkingLots
      totalApartments
      typeOfResidence
      buildingImage
      factorCap
      factor
      Company{
        id
        name
      }
      ResidentialParkingLots{
        items{
          thingsResidentialParkingLotsId
          
        }
      }
      createdAt
      updatedAt
      companiesResidentialId
    }
    nextToken
  }
}
`;
export const listElectricCarCars = `
query QueryListCar
{
  listCars{
    items {
      id
      autonomy
      batteryCapacity
      chargerCurrent
      electricPerformance
      batteryWarranty
      standardLoad
      maximumChargingPowerAC
      maximumChargingPowerDC
      detail
      availableInChile
      type
      brand
      brandModel
      gasKilometersPerLiter
      gasUsage
      createdAt
      updatedAt
    }
    nextToken
  }
}`
export const listElectricCarUsers = `
query QueryListAppUser {
  listElectricCarUsers {
    items {
      id
      fechaNacimiento
      name
      sexo
      tipoVivienda
      validated
      Company{
        id
        name
      }
      UserThings{
        items{
          id
          electricCarThings{
            id
						lastCheckedIn
						state
            Cars{
              autonomia
							capacidadBateria
							corrienteCargador
              vehicleBrand
							vehicleModel
            }
          }
        }
      }
      UserPages{
        items {
        id
    		electricCarPagesID
        electricCarPages{
          id
          name
          url
          ico
        }
      }
      }
      createdAt
      updatedAt

    }
    nextToken
  }
}
`;

export const listFaqs = /* GraphQL */ `
  query ListFaqs(
    $filter: ModelFaqsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        TagsFaq
        {
          items{
            id
            tags{
              id
              name
            }
            
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
// // filter: {isActive: {eq: true }}, limit:3
export const listThingsStatus = `
query ListThings(
  $filter: ModelThingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listThings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      activeChargeEvent
      state
      currentConsumedNow
      ResidentialParkingLots{
        items{
          Residential{
            id
          }
        }
      }
      ThingsChargeEvent(
        filter: { isActive: {eq: true}}
      ) {
        items{
          id
          startCharge
          endCharge
          chargingHours
          chargingKwh
          isActive
          type
          status
        }
      }
      updatedAt
    }
    nextToken
  }
}
`;
export const listElectricCarThings = `
query QueryListThing {
  listThings {
    items {
      id
      activeChargeEvent
      autonomia
      capacidadBateria
      corrienteCargador
      lastCheckedIn
      state
      currentConsumedNow
      ThingsUser{
        items{
          id
          usersID
        }
      }
      CarThing{
        id
        brand
        brandModel
      }
      createdAt
      updatedAt
      carsCarThingId
    }
    nextToken
  }
}
`;

// query QueryListMeasurement {
//   listMeasurements {

export const listMeasurements = `
  query ListMeasurements(
    $filter: ModelMeasurementFilterInput
    $limit: Int
    $nextToken: String
  ) {
  listMeasurements(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      date
      day
      month
      year
      Residential{
        id
        name
        Company{
          name
        }
      }
      powerFactor
      createdAt
      updatedAt
      residentialMeasurementId
    }
    nextToken
  }
}
`;
// export const listElectricCarThings = `
// query QueryListTHINGS {
//   listElectricCarThings {
//       items {
//         id
//         activeChargeEvent
//         autonomia
//         capacidadBateria
//         corrienteCargador
//         lastCheckedIn
//         state
//         vehicleBrand
//         vehicleModel
//         UserThings{
//           items{
//             id
//             electricCarUsers{
//               id
//               name
//             }
//           }
//         }
//         Cars{
//           vehicleBrand
//           vehicleModel
//           autonomia
//           capacidadBateria
//           corrienteCargador
//         }
//         createdAt
//         updatedAt
//         electricCarCarsCarsId
//       }
//       nextToken
//     }
//   }
//   `;