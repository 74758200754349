import React from 'react'
import {FiPlus} from 'react-icons/fi'
import {Link} from 'react-router-dom'

const SectionTitle = ({title, subtitle, right=false, state}) => {
  return (
    <div className="w-full mb-6 pt-3">
      <div className="flex flex-row items-center justify-between mb-4">
        <div className="flex flex-col">
          <div className="text-xs uppercase font-light text-grey-500">
            {title}
          </div>
          <div className="text-xl font-bold">{subtitle}</div>
        </div>

        {right && (
          // {children}
          <div className="flex-shrink-0 space-x-2">
            <Link  to={{
              pathname: "/user",
              state: { state }
            }} className="btn btn-circle btn-circle-lg bg-orange-500 hover:bg-orange-600 text-white" >
            <FiPlus className="stroke-current text-white" size={16} />
          
          </Link> 
            {/* <button className="btn btn-circle btn-circle-lg bg-orange-500 hover:bg-orange-600 text-white">
              <FiPlus className="stroke-current text-white" size={16} />
            </button> */}
          </div>

        )}
      </div>
    </div>
  )
}

export default SectionTitle
