



export const getItem = (key) => {
    return localStorage.getItem(key)
}

export const setItem = (key, value) => {
    return localStorage.setItem(key, value)
}

export const removeItem = (key) => {
    return localStorage.removeItem(key)
}



// export const localStorageAvailable = () => {
//     try {
//         var x = 'available:' + new Date().toISOString();
//         localStorage.setItem(x, x);
//         localStorage.removeItem(x);
//         return true;
//     } catch (e) {
//         return e instanceof DOMException && (
//             // everything except Firefox
//             e.code === 22 ||
//             // Firefox
//             e.code === 1014 ||
//             // test name field too, because code might not be present
//             // everything except Firefox
//             e.name === 'QuotaExceededError' ||
//             // Firefox
//             e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
//             // acknowledge QuotaExceededError only if there's something already stored
//             (localStorage && localStorage.length !== 0);
//     }
// }