import { fetchAxios } from "./fetchAxios";
import Amplify, { Auth } from "aws-amplify";

import config from "../aws-exports";
Amplify.configure(config);

if (process.env.NODE_ENV === "development") Amplify.Logger.LOG_LEVEL = "DEBUG";

export const fetchCurrentUser = async data => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    console.log("--fetchCurrentUser--:", user);

    return { user };

    // return user? true:false;
    // if (user) {
    //   formState = "signedIn";
    // } else {
    //   formState = "signUp";
    // }

    // const currentUser = await Auth.currentUserInfo();
    // const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    //     {
    //     bypassCache: true
    //     // Optional, By default is false. If set to true,
    //     // this call will send a request to Cognito to get the latest user data
    // }).then(data => console.log('--currentUser--', data))
    // .catch(err => console.log(err));
    // const payload = {
    //   userId: currentUser.attributes.sub,
    //   email: currentUser.attributes.email,
    //   name: currentUser.attributes.name,
    //   token: currentUser.signInUserSession.idToken.jwtToken,
    // };

    //   return currentUser
  } catch (error) {
    console.log("--ERROR--", error);
    return {};
  }
};
export const fetchAuth = async data => {
  try {
    const { email, password } = data;

    const user = await Auth.signIn({
      username: email,
      password,
    });

    //   console.log('---user.attributes---', user)
    return { user };
  } catch (error) {
    console.log("--ERROR--", error);
    return { user: {}, error };
  }
};
export const fetchCreateUser = async data => {
  try {
    // const {email, password} = data;
    console.log("---user-fetchCreateAuth---", data);

    const user = await Auth.signUp({
      username: data.username,
      password: data.password,
      attributes: {
        email: data.attributes.email,
        name: data.attributes.name,
        //   company: data.attributes.company,
      },
    });

    // const user = await Auth.signIn({
    //     username: email,
    //     password,
    //   })

    console.log("---user-fetchCreateAuth---", user);

    return { user };
  } catch (error) {
    console.log("--ERROR--", error);
    return { user: {}, error };
  }
};
export const fetchAuthSignOut = async data => {
  try {
    const user = await Auth.signOut({ global: true });
    Auth.sendCustomChallengeAnswer();
    // await Auth.currentAuthenticatedUser({ bypassCache: true });
    window.localStorage.clear();

    console.log("---SIGN OUT---", user);
    return { user };
  } catch (error) {
    console.log("--ERROR--", error);
    return { user: {}, error };
  }
};
export const fetchAuthChangePassword = async data => {
  try {
    const { email, password, newPassword } = data;

    Auth.signIn(email, password).then(user => {
      console.log("---data change user--", user);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
        Auth.completeNewPassword(
          user, // the Cognito User Object
          newPassword // the new password
          // OPTIONAL, the required attributes
          // {
          //   email: 'xxxx@example.com',
          //   phone_number: '1234567890'
          // }
        )
          .then(user => {
            // at this time the user is logged in if no MFA required
            // console.log(user);
          })
          .catch(e => {
            console.log(e);
          });
      } else {
        // other situations
        return { status: true };
      }
    });

    //   return {user}
  } catch (error) {
    console.log("--ERROR--", error);
    return { status: false, error };
  }
};
export const fetchAuthConfirmSignUp = async data => {
  try {
    /* Once the user successfully confirms their account, update form state to show the sign in form*/
    return await Auth.confirmSignUp(data.email, data.code, {
      forceAliasCreation: true,
    });
  } catch (err) {
    console.log({ err });
  }
};
export const fetchAuthResendCode = async email => {
  console.log("--DATA--", email);
  try {
    /* Once the user successfully confirms their account, update form state to show the sign in form*/
    return await Auth.resendSignUp(email);
  } catch (error) {
    console.log("--ERROR--", error);
    return { status: false, error };
  }
};

// export const fetchSession = async (data) => {

//     try{
//         const { response, loading, error, ...res } = await fetchAxios({
//         // const response = await fetchAxios({
//             method: 'POST',
//             url: '/auth/login',
//             headers: {
//               'Accept': '*/*',
//               'Content-Type': 'application/json',
//             },
//             data:{
//                     ...data
//               }
//         });

//         return { response, loading, error, res };
//         // return response;

//     }catch(error){
//         console.log('--ERROR--', error);

//     }

// }
