import React from 'react'
import SectionTitle from '../../../components/section-title';

// # basic      #Dashboard para y usuario sin privilegios
// # admin     #Dashboard para Admin energica
// # ownerCar  #Dashboard para dueños de Vehículo
// # realState #Dashboard para Inmobiliarias
// # building  #Dashboard para administrador de edificios
// # dealerCar #Dashboard para Dealer que venden vehículos


const AdminDashboard = () => {

    return (
        <>
            <SectionTitle title="Bienvenido" subtitle="Administrador Energica" />
        </>
    )
}

export default AdminDashboard;