import React, { useEffect, useState } from "react";
import { BrowserRouter, useHistory } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { initialize } from "./store/auth/actions";
import { getProfile } from "./store/profile/actions";
import { getUsers } from "./graphql/queries_custom";
// import {BrowserRouter} from 'react-router-dom';

import Layouts from "./layouts";
import LayoutLogin from "./layouts/layoutLogin";
import Routes from "./Routes";
import LoginPage from "./pages/loginPage";
// import DashboardPage from './pages/dashboardPage';

import "./css/tailwind.css";
import "./css/main.css";
import "./css/animate.css";
import "./css/_components.css";

const Wrapper = ({ getSignOut, children }) => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const [pages, setPages] = useState(["null"]);
  // const { auth:{ isAuthenticated }, auth:{ user }, auth:{status}} = useSelector(store => store);
  const getUsersWithId = async user => {
    const { email, name } = user.attributes;

    try {
      const usersPages = await API.graphql({
        query: getUsers,
        variables: { id: email },
      });

      // console.log("--getUsersWithID: ", usersPages.data.getUsers.PagesUser.items);
      setPages(usersPages.data.getUsers);
      dispatch(getProfile(
        {
          name,
          pages: [...usersPages.data.getUsers.PagesUser.items]
        }
      ));

      // setPages(usersPages.data.listUsers.items);
    } catch (err) {
      console.log({ err });
    }
  };

  const newChildren = React.Children.map(children, function (child) {
    return React.cloneElement(child, {
      typeOfDashboard: pages.dashboard,
      companiesUsersId: pages.companiesUsersId,
    })
  });

  useEffect(() => {
    dispatch(initialize());
  }, []);

  return (
    <>
      <Authenticator>
        {({ user }) => {
          if (pages[0] === "null") {
            getUsersWithId(user);
          }
          return null;
        }}
      </Authenticator>
      <Layouts getSignOut={getSignOut}>{newChildren}</Layouts>;
    </>
  );
};

const App = () => {
  const { user, signOut } = useAuthenticator(context => [context.user]);
  useEffect(() => {
    if (!user) localStorage.clear();
  }, []);

  const getSignOut = () => {
    signOut();
  };

  return (
    <BrowserRouter>
      {user ? (
        <Wrapper getSignOut={() => signOut()}>
          <Routes />
        </Wrapper>
      ) : (
        <LayoutLogin>
          <LoginPage />
        </LayoutLogin>
      )}
    </BrowserRouter>
  );
};
export default App;

{
  /* <Authenticator>

{({ signOut, user }) => user ?
(
  <BrowserRouter>
    <Wrapper>
      <Routes/>
    </Wrapper>
  </BrowserRouter>
) : (
  <LayoutLogin>
    <LoginPage/>
  </LayoutLogin>
)
}
</Authenticator> */
}

// <Authenticator>
// {({ signOut, user }) => user ? (
//   <BrowserRouter>
//     <Wrapper>
//       <Routes />
//     </Wrapper>
//   </BrowserRouter>
//   ) : (
//     <Wrapper>
//       <LoginPage/>
//     </Wrapper>
//   )
//   }

// </Authenticator>
