import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
  FiChevronRight,
  FiHome,
  FiMinus,
  FiUser,
  FiCpu,
  FiTrello,
  FiZap,
  FiPlusCircle,
  FiFileText,
  FiMapPin,
  FiMonitor,
  FiActivity,
  FiUserPlus,
  FiShoppingCart,
  FiShoppingBag,
  FiTruck,
  FiBatteryCharging,
  FiInfo,
  FiFrown,
  FiHelpCircle
} from 'react-icons/fi';
import {
  IoIosCar,
  IoIosFlash,
  IoMdOutlet,
  IoIosBusiness
} from 'react-icons/io';
import {
  BiBuildingHouse
} from 'react-icons/bi';
import {
  FcElectricalSensor
} from 'react-icons/fc';
// import {
//   BsCartCheck
// } from 'react-icons/bs';

const Item = ({ url, icon, title, items }) => {

  const icoComponent = {
    // FiHome: <FiHome size={20} />,
    FiCoffee: <FiChevronRight size={20} />,
    FiMinus: <FiMinus size={20} />,
    FiUser: <FiUser size={20} />,
    FiCpu: <FiCpu size={20} />,
    FiZap: <FiZap size={20} />,
    FiTrello: <FiTrello size={20} />,
    FiPlusCircle: <FiPlusCircle size={20} />,
    FiFileText: <FiFileText size={20} />,
    FiMapPin: <FiMapPin size={20} />,
    FiMonitor: <FiMonitor size={20} />,
    FiActivity: <FiActivity size={20} />,
    FiUserPlus: <FiUserPlus size={20} />,
    IoIosCar: <IoIosCar size={20} />,
    IoIosFlash: <IoIosFlash size={20} />,
    FiShoppingCart: <FiShoppingCart size={20} />,
    FiShoppingBag: <FiShoppingBag size={20} />,
    IoMdOutlet: <IoMdOutlet size={20} />,
    IoIosBusiness: <IoIosBusiness size={20} />,
    BiBuildingHouse: <BiBuildingHouse size={20} />,
    FcElectricalSensor: <FcElectricalSensor size={20} />,
    FiTruck: <FiTruck size={20} />,
    FiBatteryCharging: <FiBatteryCharging size={20} />,
    FiInfo: <FiInfo size={20} />,
    FiFrown: <FiFrown size={20} />,
    FiHelpCircle: <FiHelpCircle size={20} />,
    // BsCartCheck: <BsCartCheck size={20} />,

  };
  const generateIco = (i) => icoComponent[i]

  const [hidden, setHidden] = useState(true)
  let location = useLocation()
  let { pathname } = { ...location }
  let active = pathname === url ? true : false
  if (pathname === '/' && url === '/dashboard') {
    active = true
  }
  if (pathname === '/' && url !== '/dashboard') {
    active = false
  }
  if (items.length === 0) {
    return (
      <Link to={url} className={`left-sidebar-item ${active ? 'active' : ''}`}>

        {/* <FiHome size={20} /> */}

        {icon === 'FiHome' ? <FiHome size={30} color='#9e9e9e' style={{ paddingBottom: 10 }} /> : generateIco(icon)}
        {icon === 'FiHome' ? <span className="title" style={{ color: '#9e9e9e', paddingBottom: 10 }}>{title}</span> : <span className="title">{title}</span>}

      </Link>
    )
  }



  return (

    <button
      onClick={() => setHidden(!hidden)}
      className={`left-sidebar-item ${active ? 'active' : ''} ${hidden ? 'hidden-sibling' : 'open-sibling'
        }`}>

      {/* <span className="title">{title} - {icon}</span> */}
      {generateIco(icon)}
      {/* <FiChevronRight className="ml-auto arrow" /> */}
    </button>
  )
}

export default Item
