import * as types  from './constants';
import { fetchSession, fetchAuth, fetchCurrentUser, fetchAuthChangePassword, fetchAuthSignOut, fetchAuthConfirmSignUp, fetchAuthResendCode, fetchCreateUser } from '../../services/auth.services';
import { LOADING_START, LOADING_FINISHED } from '../loading/constants';
import { setItem, getItem, removeItem } from '../../utils/storageHandling';
import {errorDictionary} from '../../utils/errorAwsDictionary';
const MODULE= 'AUTH';

export const initialize = (payload) => async (dispatch, getState) => {
    const SUBMODULE= `${MODULE}-INITIALIZE`;
    try {
        
        // Validar con localStorage ,no fuinciona el deslogearse de Cognito

        const isAuth = getItem('isAuth');
        
        if(isAuth){
            const dataFetchCurrent = await fetchCurrentUser();

            const userAWS = typeof dataFetchCurrent.user === 'object' ?
            {...dataFetchCurrent.user}
            :{};
    

            console.log('---initialize-currentUser---', dataFetchCurrent)
            console.log('---initialize-userAWS---', userAWS)
            dispatch({ 
                type: types.SET_AUTH_USER, 
                payload: { 
                    user: {...userAWS},
                    isAuthenticated: true,
                    messageAuthCognito: ''
            } });

        }else{
            dispatch({ 
                type: types.SET_AUTH_USER, 
                payload: { 
                    user:{},
                    isAuthenticated: false,
                    messageAuthCognito: ''
            } });
        }
        
        


        // const authStore = JSON.parse(getItem('auth'));
        // console.log('--authStore--', authStore)

        // const dataFetchCurrent = await fetchCurrentUser();
        // console.log('---initialize-currentUser---', dataFetchCurrent)
        // console.log('---currentUser---', dataFetchCurrent.Session)
        
        // const payload = {
            //   userId: currentUser.attributes.sub,
            //   email: currentUser.attributes.email,
            //   name: currentUser.attributes.name, 
            //   token: currentUser.signInUserSession.idToken.jwtToken, 
            // };
            
            // const isAuthenticated = typeof dataFetchCurrent.attributes === 'object' ?
            // (Object.getOwnPropertyNames(dataFetchCurrent.attributes).length === 0 ) ? 
            // false:true
            // :false; 
            


    } catch (error) {
        
    }finally {
        dispatch({ type: LOADING_FINISHED, payload: SUBMODULE });
    }
}

export const logout = (payload) => async (dispatch, getState) => {
    const SUBMODULE= `${MODULE}-SIGN_OUT`;

    try {
        dispatch({ type: LOADING_START, payload: SUBMODULE });

        await fetchAuthSignOut();

        removeItem('isAuth');

        dispatch({
            type: types.SET_AUTH_CHANGE_PASSWORD,
            payload: {}
        });

    } catch (error) {
        console.log('---error---', error)
    }finally {
        dispatch({ type: LOADING_FINISHED, payload: SUBMODULE });
    }
}
export const setAuthChangePassword = (payload) => async (dispatch, getState) => {
    const SUBMODULE= `${MODULE}-SETUSERCHANGE_PASSWORD`;

    try {
        dispatch({ type: LOADING_START, payload: SUBMODULE });
        const dataFetchUser = await fetchAuthChangePassword(payload);
        

        // console.log('---dataFetchUser--', dataFetchUser.toString());
        
        
        // fetchAuthChangePassword
        // email, password, newPassword



        // SET_AUTH_CHANGE_PASSWORD
    } catch (error) {
        
    }finally {
        dispatch({ type: LOADING_FINISHED, payload: SUBMODULE });
    }
}
export const setAuthResendCode = (payload) => async (dispatch, getState) => {
    const SUBMODULE= `${MODULE}-SETCONFIRM-CODE`;

    try {
        dispatch({ type: LOADING_START, payload: SUBMODULE });
        //email and code
        const dataFetchUser = await fetchAuthResendCode(payload);
        
        dispatch({ 
            type: types.SET_AUTH_USER, 
            payload: { 
                user: {},
                isAuthenticated: false,
                messageAuthCognito: dataFetchUser?.error?.message ? dataFetchUser.error.message:'',
                status:''
                // messageAuth: isAuthenticated ? '':'Usuario o clave inválida'
            } });
        console.log('---setAuthConfirmSignUp--', dataFetchUser);
        
  
    } catch (error) {
        
    }finally {
        dispatch({ type: LOADING_FINISHED, payload: SUBMODULE });
    }
}
export const setAuthConfirmSignUp = (payload) => async (dispatch, getState) => {
    const SUBMODULE= `${MODULE}-SETCONFIRM-CODE`;

    try {
        dispatch({ type: LOADING_START, payload: SUBMODULE });
        //email and code
        const dataFetchUser = await fetchAuthConfirmSignUp(payload);
        

        console.log('---setAuthConfirmSignUp--', dataFetchUser);
        
  
    } catch (error) {
        
    }finally {
        dispatch({ type: LOADING_FINISHED, payload: SUBMODULE });
    }
}
export const setAuthCreateUser = (payload) => async (dispatch, getState) => {
    
    const SUBMODULE= `${MODULE}-SETCREATE-USER`;

    try {
        dispatch({ type: LOADING_START, payload: SUBMODULE });
        const dataFetchUser = await fetchCreateUser(payload);
        
        console.log('--dataFetchUser--', dataFetchUser.error.code)
        
            dispatch({ 
                type: types.SET_AUTH_USER, 
                payload: { 
                    user: {...dataFetchUser},
                    isAuthenticated : false,
                    messageAuthCognito: dataFetchUser?.error?.message ? dataFetchUser.error.message:'',
                    status:''
                } });

            
    }catch(error) { 
            
        console.log('---error--', error);

    }finally {
        
        dispatch({ type: LOADING_FINISHED, payload: SUBMODULE });
    }
};
export const setAuthUser = (payload) => async (dispatch, getState) => {
    
    const SUBMODULE= `${MODULE}-SIGN_IN`;
    let status ='';

    try {
        dispatch({ type: LOADING_START, payload: SUBMODULE });
        const dataFetchUser = await fetchAuth(payload);
        
        console.log('--dataFetchUser--', dataFetchUser)

        // if (dataFetchUser?.error?.code === "PasswordResetRequiredException"){
        //     status = 'change-password';
        // }

        // const isAuthenticated = Object.keys(dataFetchUser.user).length === 0 ? false:true; 
        const isAuthenticated = typeof dataFetchUser.user === 'object' ?
            Object.getOwnPropertyNames(dataFetchUser.user).length === 0 ? false:true
            :false; 

        const errorCodeAws = typeof dataFetchUser.error === 'object' ?
            dataFetchUser.error.code:'';
        const messageCognito = typeof dataFetchUser.error === 'object' ?
        errorDictionary[errorCodeAws].message:null;
        const userAWS = typeof dataFetchUser.user === 'object' ?
        {...dataFetchUser.user}
        :{};

        if (isAuthenticated) setItem('isAuth','true');
        
            dispatch({
                type: types.SET_AUTH_USER, 
                payload: { 
                    user: {...userAWS},
                    isAuthenticated,
                    messageAuthCognito: dataFetchUser?.error?.message ? messageCognito:'',
                    status
                    // messageAuth: isAuthenticated ? '':'Usuario o clave inválida'
                } });

        // }
        // dispatch(setAuth({ ...dataFetch.response }));
        // dispatch({ 
        //     type: types.SET_AUTH_USER, 
        //     payload: { 
        //         ...dataFetch.response,
        //         isAuthenticated:dataFetch.response.status === 200 && true,
        //         messageAuth: dataFetch.response.status === 200 ? '':'Usuario o password inválidas'
        //     } });
        
            // ADD TOKEN TO LOCALSTORAGE
            // if(dataFetch.response.status === 200){
            //     setItem('auth',JSON.stringify({ 
            //         ...dataFetch.response
            //     }))
            // }
            
    }catch(error) { 
            
        console.log('---error--', error);

    }finally {
        
        dispatch({ type: LOADING_FINISHED, payload: SUBMODULE });
    }
};





export const setAuth = payload => ({
    type: types.SET_AUTH_USER,
    payload
  });
export const setLogOut = payload => ({
    type: types.SET_AUTH_CHANGE_PASSWORD,
    payload
  });