import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { ElectricCar } from '../../components/assets/electricCar';


import { getResidential } from "../../graphql/queries_custom";
import { onUpdateThings } from '../../graphql/subscriptions_custom';

import { IoMdImages } from 'react-icons/io';
import { BsBuilding } from "react-icons/bs";
import SectionTitle from '../../components/dashboard/section-title'


import Widget from '../../components/widget'

const PATH_ITEMS = "getResidential";
const TIME_OFFLINE = 10;
const typeOfColor = {
    // eslint-disable-next-line no-useless-computed-key
    [""]: "text-grey-300",
    // eslint-disable-next-line no-useless-computed-key
    ["on"]: "text-green-400",
    // eslint-disable-next-line no-useless-computed-key
    ["off"]: "text-blue-400",
    // eslint-disable-next-line no-useless-computed-key
    ["offline"]: "text-red-400",
};
const typeOfColorHexadecimal = {
    // eslint-disable-next-line no-useless-computed-key
    [""]: "text-grey-300",
    // eslint-disable-next-line no-useless-computed-key
    ["on"]: "rgba(102, 187, 106, 1)",
    // eslint-disable-next-line no-useless-computed-key
    ["off"]: "rgba(66, 165, 245, 1)",
    // eslint-disable-next-line no-useless-computed-key
    ["offline"]: "rgba(239, 83, 80, 1)",
};
const typeOfName = {
    // eslint-disable-next-line no-useless-computed-key
    [""]: "",
    // eslint-disable-next-line no-useless-computed-key
    ["on"]: "Cargando",
    // eslint-disable-next-line no-useless-computed-key
    ["off"]: "Disponible",
    // eslint-disable-next-line no-useless-computed-key
    ["offline"]: "Offline",
    ["habilitado"]: "Cargador habilitado",
};

const CreateParking = ({ parkingLots }) => {

    return (
        <div className="flex flex-row flex-wrap w-full mb-4 justify-start">
            {
                parkingLots.map((item, index) => {

                    const diffTime = Math.abs(new Date(item?.Things?.updatedAt) - new Date());
                    const minutes = Math.floor((diffTime / 1000) / 60);

                    return (
                        <div className={`flex flex-col justify-center border border-grey-200 p-2  my-2 mx-2 w-96 h-64 min-w-min min-h-min
                            ${minutes >= TIME_OFFLINE ? typeOfColor["offline"] : typeOfColor[item?.Things?.state || ""]} 
                            text-grey
                        `}>
                            {/* <div className={`flex flex-col ${index <= Number(totalElectric) ? bgColor : null} border border-grey-200 p-2  my-2 mx-2`}> */}
                            <div className={`bg-gray-300 flex flex-col items-center justify-center p-4`}>
                                <p className="text-sm font-light text-grey-500">
                                    {item.name} | <span className="text-grey-900">{item.thingsResidentialParkingLotsId}</span>
                                </p>
                                {/* <p>{item.Things.state} - {item?.Things?.currentConsumedNow || 0}</p> */}
                                <div className={`bg-gray-300 flex flex-row items-center justify-between p-4`}>
                                    {/* <IoIosOutlet key={index} size={60} color={`${minutes >= TIME_OFFLINE ? typeOfColor["offline"] : typeOfColor[item?.Things?.state || ""]}`} /> */}
                                    {/* <MdDirectionsCar key={index} size={60} color={`${minutes >= TIME_OFFLINE ? typeOfColor["offline"] : typeOfColor[item?.Things?.state || ""]}`} /> */}
                                    <ElectricCar color={`${minutes >= TIME_OFFLINE ? typeOfColorHexadecimal["offline"] : typeOfColorHexadecimal[item?.Things?.state || ""]}`} />



                                    {/* 
                                        Rojo: Offline
                                        Azul: Disponible
                                        Verde sin corriente: Cargador habilitado
                                        Verde con corriente: Cargando 
                                    */}

                                    <p className={`w-24 ml-4 text-lg font-light ${minutes >= TIME_OFFLINE ? typeOfColor["offline"] : typeOfColor[item?.Things?.state || ""]}`} >
                                        {

                                            Number(minutes) >= Number(TIME_OFFLINE) ? (
                                                //OFFLINE
                                                typeOfName["offline"]
                                            ) : (
                                                item.Things.state === "on" ? (
                                                    // ON
                                                    // CARGANDO
                                                    item?.Things?.currentConsumedNow > 0 || 0 ? (
                                                        typeOfName[item?.Things?.state || ""]
                                                    ) : (
                                                        // CARGADOR HABILITADO (NO HAY CONSUMO)
                                                        typeOfName["habilitado"]
                                                    )

                                                ) : (
                                                    //OFF
                                                    typeOfName[item?.Things?.state || ""]
                                                )
                                            )
                                            // item?.Things?.currentConsumedNow > 0 ?
                                            //     typeOfName[item?.Things?.state || ""]
                                            //     : (
                                            //         item?.Things?.state === "off" ?
                                            //             typeOfName[item?.Things?.state || ""]
                                            //             : typeOfName["habilitado"]
                                            //     )
                                        }
                                    </p>

                                </div>
                                {/* <p className="text-sm font-light text-white-800 mt-2"></p> */}
                                {minutes > 0 ? <span>{Number(minutes)} min. sin responder</span> : null}
                                {/* {minutes > 0 ? <span>{Number(TIME_OFFLINE) + Number(minutes)} min. sin responder</span> : null} */}

                                {item?.Things?.currentConsumedNow > 0 ? <span>Corriente: {Number(item?.Things?.currentConsumedNow).toFixed(1)} A</span> : null}
                            </div>

                        </div>
                    )
                })
            }
        </div>
    )
}

const ViewResidential = (props) => {
    const [dataResidential, setDataResidential] = useState([]);
    const { idResidential } = props;

    const getAPI = async () => {
        try {
            const filterDetail = { id: idResidential };
            const dataResidentialQuery = await API.graphql({
                query: getResidential,
                // variables: { ...filterDetail }
            });
            // console.log('--dataResidentialQuery.data[PATH_ITEMS]--', dataResidentialQuery.data[PATH_ITEMS])
            console.log('--dataResidentialQuery.data[PATH_ITEMS]--', dataResidentialQuery.data[PATH_ITEMS].ResidentialParkingLots.items)

            setDataResidential(dataResidentialQuery.data[PATH_ITEMS]);

        } catch (err) {
            console.log({ err });
        }
    }

    useEffect(() => {
        const changeInservice = API.graphql(
            graphqlOperation(onUpdateThings, {})
        ).subscribe({
            next: ({ provider, value }) => getAPI(),
            error: error => console.warn(error)
            //next: ({ provider, value }) => {
            // my code ...
            //},
            //error: (error) => console.log("update inservice ", error.error.errors[0]),
        });

        return () => changeInservice.unsubscribe();    // <== add this one
    }, []);


    useEffect(() => {

        getAPI();

    }, [idResidential]);

    // useEffect(() => { getAPI(); }, []);

    return (
        <Widget title="" >
            {
                idResidential ?
                    (
                        <>
                            <div className="flex flex-col sm:flex-row w-full mb-4 justify-center">

                                <div className="pr-10 flex items-center flex-col">
                                    {dataResidential?.buildingImage ?
                                        (
                                            <BsBuilding size={120} color='rgb(220, 220, 220)' />
                                            // <img
                                            //     key={"IMG-01"}
                                            //     src={dataResidential?.buildingImage || "-"}
                                            //     alt="media"
                                            //     className={`rounded min-w-[50px] max-w-xs shadow-outline-white`}
                                            // />
                                        )
                                        : (
                                            <div className={`bg-gray-300 min-w-[250px] max-h-xs flex flex-col items-center justify-center space-x-2`}>
                                                <IoMdImages size={120} color='rgb(220, 220, 220)' />

                                                <p className="text-sm font-light text-grey-500">Sin imagen</p>
                                            </div>
                                        )
                                    }
                                    <span className="mt-5 text-sm font-light text-grey-500">{idResidential}</span>
                                </div>
                                <div className="w-5/6 md:w-full justify-center">
                                    <table className="table no-border striped">
                                        <thead>
                                            <tr>
                                                <th width='140px' key={1}>{''}</th>
                                                <th key={'DATA-2'}>{''}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={"DATA-1"}>
                                                <td key={"DATA-1a"}><p className="text-sm font-light text-grey-500">Compañia</p></td>
                                                <td key={"DATA-1b"}>{dataResidential?.Company?.name || "-"}</td>
                                            </tr>
                                            <tr key={"DATA-2"}>
                                                <td key={"DATA-1a"}><p className="text-sm font-light text-grey-500">Residencia</p></td>
                                                <td key={"DATA-1b"}>{dataResidential?.name || "-"}</td>
                                            </tr>
                                            <tr key={"DATA-3"}>
                                                <td key={"DATA-3a"}><p className="text-sm font-light text-grey-500">Dirección</p></td>
                                                <td key={"DATA-3b"}>{dataResidential?.address || "-"}</td>
                                            </tr>
                                            <tr key={"DATA-4"}>
                                                <td key={"DATA-4a"}><p className="text-sm font-light text-grey-500">Estacionamientos</p></td>
                                                <td key={"DATA-4b"}>{dataResidential?.totalParkingLots || "-"}</td>
                                            </tr>
                                            <tr key={"DATA-5"}>
                                                <td key={"DATA-5a"}><p className="text-sm font-light text-grey-500">Potencia declarada</p></td>
                                                <td key={"DATA-5b"}>{dataResidential?.declaredPower || "-"}</td>
                                            </tr>
                                            <tr key={"DATA-5"}>
                                                <td key={"DATA-5a"}><p className="text-sm font-light text-grey-500">Factor de gestión de carga</p></td>
                                                <td key={"DATA-5b"}>{"1"}</td>
                                            </tr>
                                            <tr key={"DATA-5"}>
                                                <td key={"DATA-5a"}><p className="text-sm font-light text-grey-500">Tipo de comunidad</p></td>
                                                <td key={"DATA-5b"}>{dataResidential?.typeOfCommunity || "-"}</td>
                                            </tr>
                                            <tr key={"DATA-5"}>
                                                <td key={"DATA-5a"}><p className="text-sm font-light text-grey-500">Contacto</p></td>
                                                <td key={"DATA-5b"}>{dataResidential?.contactName || "-"} | {dataResidential?.contactEmail || "-"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            < SectionTitle title="Estado Eve" subtitle="Estacionamientos"
                                right={false} state={{ user: {} }}
                            />
                            < div>
                                <div className="flex flex-col sm:flex-row w-full mb-4 justify-start">
                                    <CreateParking
                                        parkingLots={dataResidential?.ResidentialParkingLots?.items || []}
                                    />
                                </div>
                                {console.log('--dataResidential--', dataResidential?.ResidentialParkingLots?.items)}
                            </div >

                        </>

                    )
                    : null
            }



        </Widget>
    )
}


export default ViewResidential;