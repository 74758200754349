import React from 'react'
import {Link} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/auth/actions';


import {FiUser, FiLogIn} from 'react-icons/fi'

const AccountLinks = ({getSignOut}) => {
  const dispatch = useDispatch();
  const history = useHistory();


  const handlerSigOut = async (e) => {
    e.preventDefault();
    getSignOut()
    // dispatch(logout());
    // history.push('/')
  };


  const items = [
    {
      url: '/user-profile',
      icon: <FiUser size={18} className="stroke-current" />,
      name: 'Perfil de Usuario',
      badge: null
    },
    // {
    //   url: '/pages/logout',
    //   icon: <FiLogIn size={18} className="stroke-current" />,
    //   name: 'Logout',
    //   badge: null
    // }
  ]

  return (
    <div className="flex flex-col w-full">
      <ul className="list-none">
        {items.map((item, i) => (
          <li key={i} className="dropdown-item">
            <Link
              to={item.url}
              className="flex flex-row items-center justify-start h-10 w-full px-2">
              {item.icon}
              <span className="mx-2">{item.name}</span>
              {item.badge && (
                <span
                    className={`uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full ${item.badge.color} ml-auto`}>
                  {item.badge.number}
                </span>
              )}
            </Link>
          </li>
        ))}
        <li key="LOGOUT002" className="dropdown-item">
          <a href="" onClick={ (e)=>handlerSigOut(e) } className="flex flex-row items-center justify-start h-10 w-full px-2">
            <FiLogIn size={18} className="stroke-current" />
            <span className="mx-2">Salir</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default AccountLinks
